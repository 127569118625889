import React from 'react';

export function ItemNotFoundIcon(): JSX.Element {
  return (
    <svg fill='none' height='306' viewBox='0 0 306 306' width='306' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M306 153C306 237.5 237.5 306 153 306C68.5004 306 0 237.5 0 153C0 68.5004 68.5004 0 153 0C237.5 0 306 68.5004 306 153Z'
        fill='var(--color-opal-40)'
        opacity='0.05'
      />
      <path
        d='M277.935 153.453C277.935 222.703 222.202 278.841 153.453 278.841C84.7031 278.841 28.9705 222.703 28.9705 153.453C28.9705 84.2034 84.7031 28.0654 153.453 28.0654C222.202 28.0654 277.935 84.2034 277.935 153.453Z'
        fill='var(--color-opal-40)'
        opacity='0.08'
      />
      <path
        d='M253.491 153.453C253.491 208.702 208.702 253.491 153.453 253.491C98.2028 253.491 53.4141 208.702 53.4141 153.453C53.4141 98.203 98.2028 53.4143 153.453 53.4143C208.702 53.4143 253.491 98.203 253.491 153.453Z'
        fill='var(--color-opal-60)'
        opacity='0.1'
      />
      <g filter='url(#filter0_d_1669_64134)'>
        <path
          d='M41.4343 80.8441C41.4343 74.1643 46.8494 68.7493 53.5292 68.7493H252.471C259.151 68.7493 264.566 74.1643 264.566 80.8441V222.902C264.566 229.582 259.151 234.997 252.471 234.997H53.5292C46.8494 234.997 41.4343 229.582 41.4343 222.902V80.8441Z'
          fill='white'
        />
      </g>
      <g filter='url(#filter1_d_1669_64134)'>
        <path
          clipRule='evenodd'
          d='M252.471 69.9588H53.5292C47.5174 69.9588 42.6438 74.8323 42.6438 80.8441V222.902C42.6438 228.914 47.5174 233.787 53.5292 233.787H252.471C258.483 233.787 263.357 228.914 263.357 222.902V80.8441C263.357 74.8323 258.483 69.9588 252.471 69.9588ZM53.5292 68.7493C46.8494 68.7493 41.4343 74.1643 41.4343 80.8441V222.902C41.4343 229.582 46.8494 234.997 53.5292 234.997H252.471C259.151 234.997 264.566 229.582 264.566 222.902V80.8441C264.566 74.1643 259.151 68.7493 252.471 68.7493H53.5292Z'
          fill='var(--color-opal-40)'
          fillOpacity='0.47'
          fillRule='evenodd'
          shapeRendering='crispEdges'
        />
      </g>
      <path
        d='M119.695 117.804C119.695 116.605 120.666 115.634 121.864 115.634H161.557C162.106 115.634 162.635 115.842 163.037 116.217L169.599 122.34L176.295 129.103C176.697 129.509 176.922 130.058 176.922 130.63V191.576C176.922 192.774 175.951 193.746 174.753 193.746H121.864C120.666 193.746 119.695 192.774 119.695 191.576V117.804Z'
        fill='white'
      />
      <path
        clipRule='evenodd'
        d='M171.867 120.004L178.608 126.813C179.613 127.829 180.177 129.2 180.177 130.629V191.576C180.177 194.571 177.748 197 174.753 197H121.864C118.869 197 116.44 194.571 116.44 191.576V117.803C116.44 114.808 118.869 112.379 121.864 112.379H161.557C162.931 112.379 164.253 112.9 165.258 113.837L171.867 120.004ZM121.864 115.634C120.666 115.634 119.695 116.605 119.695 117.803V191.576C119.695 192.774 120.666 193.745 121.864 193.745H174.753C175.951 193.745 176.922 192.774 176.922 191.576V130.629C176.922 130.058 176.697 129.509 176.295 129.103L169.599 122.34L163.037 116.217C162.635 115.842 162.106 115.634 161.557 115.634H121.864Z'
        fill='var(--color-opal-40)'
        fillRule='evenodd'
      />
      <path
        d='M162.819 126.922V116.882C162.819 115.942 163.931 115.447 164.629 116.075L176.991 127.2C177.73 127.866 177.259 129.092 176.265 129.092H164.989C163.79 129.092 162.819 128.12 162.819 126.922Z'
        fill='#C6D0D9'
      />
      <path
        clipRule='evenodd'
        d='M164.446 118.1V126.922C164.446 127.221 164.689 127.464 164.989 127.464H174.851L164.446 118.1ZM161.191 116.882C161.191 114.533 163.973 113.295 165.718 114.866L178.079 125.991C179.927 127.654 178.751 130.719 176.265 130.719H164.989C162.891 130.719 161.191 129.019 161.191 126.922V116.882Z'
        fill='var(--color-opal-40)'
        fillRule='evenodd'
      />
      <path
        d='M126.475 140.044C126.475 138.788 127.493 137.771 128.748 137.771H164.992C166.248 137.771 167.265 138.788 167.265 140.044C167.265 141.299 166.248 142.316 164.992 142.316H128.748C127.493 142.316 126.475 141.299 126.475 140.044Z'
        fill='var(--color-opal-40)'
        opacity='0.5'
      />
      <path
        d='M126.475 131.365C126.475 130.109 127.493 129.092 128.748 129.092H151.975C153.231 129.092 154.248 130.109 154.248 131.365C154.248 132.62 153.231 133.637 151.975 133.637H128.748C127.493 133.637 126.475 132.62 126.475 131.365Z'
        fill='var(--color-teal-50)'
      />
      <path
        d='M126.475 155.232C126.475 153.977 127.493 152.959 128.748 152.959H164.992C166.248 152.959 167.265 153.977 167.265 155.232C167.265 156.487 166.248 157.505 164.992 157.505H128.748C127.493 157.505 126.475 156.487 126.475 155.232Z'
        fill='var(--color-opal-40)'
        opacity='0.5'
      />
      <path
        d='M126.475 170.421C126.475 169.165 127.493 168.148 128.748 168.148H164.992C166.248 168.148 167.265 169.165 167.265 170.421C167.265 171.676 166.248 172.693 164.992 172.693H128.748C127.493 172.693 126.475 171.676 126.475 170.421Z'
        fill='var(--color-opal-40)'
        opacity='0.5'
      />
      <path
        d='M126.475 147.638C126.475 146.383 127.493 145.365 128.748 145.365H164.992C166.248 145.365 167.265 146.383 167.265 147.638C167.265 148.893 166.248 149.911 164.992 149.911H128.748C127.493 149.911 126.475 148.893 126.475 147.638Z'
        fill='var(--color-opal-40)'
        opacity='0.5'
      />
      <path
        d='M126.475 162.826C126.475 161.571 127.493 160.554 128.748 160.554H164.992C166.248 160.554 167.265 161.571 167.265 162.826C167.265 164.082 166.248 165.099 164.992 165.099H128.748C127.493 165.099 126.475 164.082 126.475 162.826Z'
        fill='var(--color-opal-40)'
        opacity='0.5'
      />
      <path
        d='M126.475 178.015C126.475 176.759 127.493 175.742 128.748 175.742H164.992C166.248 175.742 167.265 176.759 167.265 178.015C167.265 179.27 166.248 180.287 164.992 180.287H128.748C127.493 180.287 126.475 179.27 126.475 178.015Z'
        fill='var(--color-opal-40)'
        opacity='0.5'
      />
      <path
        d='M192.76 184.651C192.76 195.885 183.653 204.993 172.419 204.993C161.184 204.993 152.077 195.885 152.077 184.651C152.077 173.417 161.184 164.309 172.419 164.309C183.653 164.309 192.76 173.417 192.76 184.651Z'
        fill='#E8ECF0'
      />
      <path
        clipRule='evenodd'
        d='M172.419 201.738C181.855 201.738 189.505 194.088 189.505 184.651C189.505 175.214 181.855 167.564 172.419 167.564C162.982 167.564 155.332 175.214 155.332 184.651C155.332 194.088 162.982 201.738 172.419 201.738ZM172.419 204.993C183.653 204.993 192.76 195.885 192.76 184.651C192.76 173.417 183.653 164.309 172.419 164.309C161.184 164.309 152.077 173.417 152.077 184.651C152.077 195.885 161.184 204.993 172.419 204.993Z'
        fill='var(--color-opal-40)'
        fillRule='evenodd'
      />
      <path
        d='M186.435 184.651C186.435 192.392 180.16 198.668 172.419 198.668C164.677 198.668 158.402 192.392 158.402 184.651C158.402 176.91 164.677 170.634 172.419 170.634C180.16 170.634 186.435 176.91 186.435 184.651Z'
        fill='white'
      />
      <path
        clipRule='evenodd'
        d='M172.419 195.956C178.662 195.956 183.723 190.894 183.723 184.651C183.723 178.408 178.662 173.346 172.419 173.346C166.175 173.346 161.114 178.408 161.114 184.651C161.114 190.894 166.175 195.956 172.419 195.956ZM172.419 198.668C180.16 198.668 186.435 192.392 186.435 184.651C186.435 176.91 180.16 170.634 172.419 170.634C164.677 170.634 158.402 176.91 158.402 184.651C158.402 192.392 164.677 198.668 172.419 198.668Z'
        fill='var(--color-opal-40)'
        fillRule='evenodd'
      />
      <path
        d='M171.08 186.872H173.219L173.773 178.351H170.508L171.08 186.872ZM170.545 189.841C170.545 190.727 171.246 191.409 172.15 191.409C173.053 191.409 173.754 190.727 173.754 189.841C173.754 188.938 173.053 188.255 172.15 188.255C171.246 188.255 170.545 188.938 170.545 189.841Z'
        fill='var(--color-brand-secondary-terra)'
      />
      <path
        d='M182.628 198.928L187.571 194.693L197.443 206.214C198.613 207.579 198.454 209.634 197.089 210.803C195.724 211.973 193.67 211.815 192.5 210.45L182.628 198.928Z'
        fill='var(--color-opal-40)'
      />
      <path
        d='M41.4343 81.723C41.4343 75.0432 46.8494 69.6282 53.5292 69.6282H251.558C258.238 69.6282 263.653 75.0432 263.653 81.723V95.0274H41.4343V81.723Z'
        fill='#C5CFD9'
        opacity='0.7'
      />
      <path
        clipRule='evenodd'
        d='M263.653 94.9446H42.9368V93.7351H263.653V94.9446Z'
        fill='#C9D3DC'
        fillRule='evenodd'
      />
      <circle cx='56' cy='83' fill='var(--color-opal-40)' r='3.5' />
      <circle cx='67' cy='83' fill='var(--color-opal-40)' r='3.5' />
      <circle cx='78' cy='83' fill='var(--color-opal-40)' r='3.5' />
      <defs>
        <filter
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
          height='216.248'
          id='filter0_d_1669_64134'
          width='273.132'
          x='16.4343'
          y='51.7493'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='8' />
          <feGaussianBlur stdDeviation='12.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.598594 0 0 0 0 0.659243 0 0 0 0 0.735055 0 0 0 0.3 0' />
          <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_1669_64134' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_1669_64134' mode='normal' result='shape' />
        </filter>
        <filter
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
          height='216.248'
          id='filter1_d_1669_64134'
          width='273.132'
          x='16.4343'
          y='51.7493'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dy='8' />
          <feGaussianBlur stdDeviation='12.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.598594 0 0 0 0 0.659243 0 0 0 0 0.735055 0 0 0 0.3 0' />
          <feBlend in2='BackgroundImageFix' mode='normal' result='effect1_dropShadow_1669_64134' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_1669_64134' mode='normal' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
