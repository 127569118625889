import { noop } from 'lodash';

import { generateRowActions, getLinkCellColumn, type GridColDef } from 'src/app/components/lib/table';
import { useNotificationActions } from 'src/app/state/notifications';

import { openOrDownload } from '../utils';

import type { DocumentsProps } from '../components/documents';
import type { DocumentSchema } from '../schema';

export type UseDocumentsProps = Omit<DocumentsProps, 'openAddDocumentModal' | 'setSearch'>;

export function useDocuments({
  documents,
  isReadonly,
  openEditDocumentModal = noop,
  removeDocument = noop,
  search,
}: UseDocumentsProps) {
  const notification = useNotificationActions();
  const filteredDocuments = documents.filter((doc) => doc.documentName.toLowerCase().includes(search.toLowerCase()));
  const columns: GridColDef<DocumentSchema>[] = [
    getLinkCellColumn({
      column: {
        field: 'documentName',
        headerName: 'Document Name',
        sortable: false,
      },
      options: {
        linkAction: ({ row }) => {
          try {
            const { documentTypeId, fileUrl, url, fileName, userFileName } = row as DocumentSchema;
            openOrDownload(documentTypeId, fileUrl ?? '', url ?? '', userFileName ?? fileName ?? '');
          } catch (error) {
            notification.error({ message: 'Error opening file' });
          }
        },
        shouldRenderLink: true,
      },
    }),
    {
      field: 'documentTypeId',
      headerName: 'DocumentType',
      sortable: false,
      valueGetter: ({ row }) => (row.documentTypeId === 1 ? 'File' : 'Link'),
    },
    generateRowActions(
      [
        {
          visible: !isReadonly,
          value: 'Remove',
          onClick: (row: DocumentSchema) => {
            const rowIndex = documents.findIndex((doc) => doc.uuid === row.uuid);
            removeDocument(rowIndex);
          },
        },
        {
          visible: !isReadonly,
          value: 'Edit',
          onClick: (row: DocumentSchema) => {
            const document = documents.find((doc) => doc.uuid === row.uuid);
            if (document) {
              openEditDocumentModal(document);
            }
          },
        },
      ],
      { loading: false, rowId: '' },
      false,
      true
    ),
  ];
  return { columns, filteredDocuments };
}
