import React, { useState } from 'react';

import { Documents } from 'src/app/pages/manufacturing/shared/components/documents';

import { useAssemblyFormStateContext } from '../../use-form-state';

export function AssemblyDocuments() {
  const [search, setSearch] = useState('');
  const [
    {
      formData: { documents },
    },
  ] = useAssemblyFormStateContext();
  return <Documents documents={documents} isReadonly search={search} setSearch={setSearch} />;
}
