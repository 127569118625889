import React, { useEffect, useMemo } from 'react';

import { noop } from 'lodash';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import BlockSpinner from 'src/app/components/block/block-spinner';
import { AlertBannerStyles, AlertBanner } from 'src/app/components/lib/alert-banner';
import { RedErrorBadge } from 'src/app/components/lib/badge-v2';
import { Button } from 'src/app/components/lib/button';
import { Divider } from 'src/app/components/lib/divider';
import { FormContainer, FormSection } from 'src/app/components/lib/form';
import { Input } from 'src/app/components/lib/input';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { Select } from 'src/app/components/lib/select';
import { Toggle } from 'src/app/components/lib/toggle';
import {
  type ProcessingJobTypeAttributes,
  useProcessingJobTypeResources,
  type ProcessingJobTypeCategory,
} from 'src/app/queries/metrc/get-processing-job-type-resources';

import { EMPTY_ATTRIBUTES_BODY, EMPTY_ATTRIBUTES_HEADER } from '../constants';
import { EmptyMessage } from '../layout';

import type { ProcessingJobTypeTemplateSchema } from '../schema';

export type SetJobType = <K extends keyof ProcessingJobTypeTemplateSchema>(
  key: K,
  value: ProcessingJobTypeTemplateSchema[K]
) => void;

export type ProcessingJobFormProps = {
  alertBannerText: string;
  errors: Record<string, string>;
  formValues: ProcessingJobTypeTemplateSchema;
  isReadonly: boolean;
  openEditAttributesModal?: (metrcAttributes: ProcessingJobTypeAttributes[]) => void;
  setCategoryOptionsContext?: (categoryOptions: ProcessingJobTypeCategory[]) => void;
  setValue?: SetJobType;
};
export function ProcessingJobForm({
  alertBannerText,
  errors,
  formValues: { attributes, categoryName, description, isProcessingJob, name, processingSteps },
  isReadonly,
  openEditAttributesModal = noop,
  setValue = noop,
  setCategoryOptionsContext,
}: ProcessingJobFormProps) {
  const { data, isLoading } = useProcessingJobTypeResources();
  const { Categories: metrcCategories, Attributes: metrcAttributes = [] } = data ?? {};
  // Category options for UI selection
  const categoryOptions = useMemo(
    () => (metrcCategories ?? []).filter(({ ForProcessingJobs }) => ForProcessingJobs),
    [metrcCategories]
  );
  // Effect to set the context when the category options are fetched
  useEffect(() => {
    if (setCategoryOptionsContext) {
      setCategoryOptionsContext(categoryOptions);
    }
  }, [setCategoryOptionsContext, categoryOptions]);
  // Attribute rows for table
  const attributeRows = attributes?.map((attribute: string) => ({ name: attribute, id: uuidv4() })) ?? [];
  return (
    <>
      <AlertBanner marginTop='0' style={AlertBannerStyles.info} text={alertBannerText} />
      <FormContainer>
        <BlockSpinner visible={isLoading} />
        {!isLoading && (
          <>
            <FormSection>
              {/* Is Processing Job */}
              <Toggle
                checked={isProcessingJob}
                disabled={isReadonly}
                label='Use as processing job type:'
                onChange={() => setValue('isProcessingJob', !isProcessingJob)}
              />
              {/* Name */}
              <Input
                disabled={!isProcessingJob || isReadonly}
                error={!!errors?.name}
                label='Name:'
                required
                value={name}
                onChange={({ target }) => setValue('name', target.value)}
              />
              {/* Category */}
              <Select
                disabled={!isProcessingJob || isReadonly}
                label='Category:'
                placeholder='Select a category'
                required={isProcessingJob}
                value={categoryName}
                onChange={({ target }) => setValue('categoryName', target.value)}
              >
                {categoryOptions.map((category) => (
                  <MenuItem key={category.Id} value={category.Name}>
                    {category.Name}
                  </MenuItem>
                ))}
              </Select>
              {/* Description */}
              <Input
                disabled={!isProcessingJob || isReadonly}
                error={!!errors.description}
                label='Description:'
                maxlength={1000}
                multiline
                required={isProcessingJob}
                rows={4}
                showCharacterCount
                value={description ?? ''}
                onChange={({ target }) => setValue('description', target.value)}
              />
              {/* Processing Steps */}
              <Input
                disabled={!isProcessingJob || isReadonly}
                error={!!errors.processingSteps}
                label='Processing steps:'
                maxlength={2500}
                multiline
                required={isProcessingJob}
                rows={4}
                showCharacterCount
                value={processingSteps ?? ''}
                onChange={({ target }) => setValue('processingSteps', target.value)}
              />
            </FormSection>
            <Divider variant='form-section-divider' width='540px' />
            <AttributesCard>
              <AttributesHeader>
                <AttributesHeaderLeft>
                  Attributes
                  {!!errors.attributes && <RedErrorBadge label='Required' size='small' />}
                </AttributesHeaderLeft>
                <Button
                  buttonSize='small'
                  disabled={isReadonly || !isProcessingJob}
                  label='Edit'
                  variant='white'
                  onClick={() => openEditAttributesModal(metrcAttributes)}
                />
              </AttributesHeader>
              <AttributesBody>
                {attributeRows.length > 0 ? (
                  <InnerAttributesCard>
                    {attributeRows.map((row) => (
                      <AttributeItem key={row.id}>{row.name}</AttributeItem>
                    ))}
                  </InnerAttributesCard>
                ) : (
                  <EmptyMessage body={EMPTY_ATTRIBUTES_BODY} header={EMPTY_ATTRIBUTES_HEADER} />
                )}
              </AttributesBody>
            </AttributesCard>
          </>
        )}
      </FormContainer>
    </>
  );
}

const InnerAttributesCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--color-gray-20);
  background: var(--color-grayscale-white);
  width: 100%;
`;

const AttributesCard = styled(InnerAttributesCard)`
  width: 540px;
  margin-bottom: var(--sizes-70);
`;

const AttributeItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 32px 12px 16px;
  align-items: flex-start;
  /* Add border between items except the last one */
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-20);
  }
  color: var(--color-grayscale-black);
  font: var(--font-small-13pt-normal);
  line-height: 18px;
  letter-spacing: 0.065px;
`;

const AttributesHeader = styled.div`
  display: flex;
  padding: var(--sizes-40);
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid var(--color-gray-20);
`;

const AttributesHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: var(--color-grayscale-black);
  font: var(--font-large-16pt-semibold);
  line-height: var(--sizes-60);
`;

const AttributesBody = styled.div`
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
