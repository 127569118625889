import React from 'react';

import { format } from 'date-fns';

import Translate from 'src/app_deprecated/components/form/layout/Translate.react';

import { CustomerTypeDropdown } from 'src/app/components/form-elements/dropdowns/customer-types-multiple';
import { CustomerTypeSingleSelectDropdown } from 'src/app/components/form-elements/dropdowns/customer-types-single';
import { GenderOptionDropdown } from 'src/app/components/form-elements/dropdowns/gender-options-multiple';
import { ProductCategoryDropdown } from 'src/app/components/form-elements/dropdowns/product-category-multiple';
import { StateAndProvinceDropdown } from 'src/app/components/form-elements/dropdowns/state-province-dropdown';
import { DatePicker } from 'src/app/components/lib/date-picker';
import { InputAdornment } from 'src/app/components/lib/input';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

import { getOperatorsFromOperatorGroup, OperatorGroupEnum, ValueEnum } from './operators';
import { Value, LabelContainer, BottomAlignedLabel, StyledSelect, StyledInput } from './query-builder.styles';

import type { OperatorGroup } from './operators';
import type { Variant } from './schema';

type OperatorInputProps = {
  disabled?: boolean;
  index: number;
  onOperatorChange: (operator: string) => void;
  onValueChange: (value: string, secondValue?: boolean) => void;
  onVariantChange: (variant: string) => void;
  operator?: string;
  operatorGroup: OperatorGroup;
  secondValue?: string | null;
  value: string;
  variant?: string | null;
  variants: Variant[];
};

export function OperatorInput({
  operatorGroup,
  operator,
  value,
  onOperatorChange,
  onValueChange,
  onVariantChange,
  secondValue,
  variant = '',
  disabled,
  variants,
  index,
}: OperatorInputProps) {
  const enableMultiSelectDropdowns = useLDFlag<boolean>(LD.CUSTOMERS_MULTISELECTS_QUERY_BUILDER);
  const operators = getOperatorsFromOperatorGroup(operatorGroup, enableMultiSelectDropdowns);

  const renderInput = (operator) => {
    if (!operator) {
      return null;
    }

    switch (operator.valueType) {
      case ValueEnum.Boolean:
        return (
          <StyledSelect
            disabled={disabled}
            paddingRightInput='42px'
            placeholder='select a value'
            sx={{ minWidth: '100px' }}
            value={value}
            onChange={({ target }) => onValueChange(target.value)}
          >
            <MenuItem value='1'>true</MenuItem>
            <MenuItem value='0'>false</MenuItem>
          </StyledSelect>
        );
      case ValueEnum.Date:
        return (
          <DatePicker
            allowPastDate
            date={value}
            disableClearDate
            disabled={disabled}
            key='date'
            placeholder='select a date'
            onDateChange={(newDate) => {
              const formattedDate = format(newDate, 'yyyy-MM-dd');
              onValueChange(formattedDate);
            }}
          />
        );
      case ValueEnum.Percent:
        return (
          <>
            <StyledInput
              disabled={disabled}
              endAdornment={<InputAdornment position='end'>%</InputAdornment>}
              type='number'
              value={value}
              onChange={({ target }) => onValueChange(target.value)}
            />
            {variants?.length > 0 && (
              <StyledSelect
                disabled={disabled}
                paddingRightInput='42px'
                placeholder='select a value'
                value={variant ?? ''}
                onChange={({ target }) => onVariantChange(target.value)}
              >
                {variants?.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          </>
        );
      case ValueEnum.Number:
        return (
          <>
            <StyledInput
              disabled={disabled}
              endAdornment={
                operatorGroup === OperatorGroupEnum.Date || operatorGroup === OperatorGroupEnum.HistoricDate ? (
                  <InputAdornment position='end'>days</InputAdornment>
                ) : null
              }
              type='number'
              value={value}
              onChange={({ target }) => onValueChange(String(target.value))}
            />
            {variants?.length > 0 && (
              <StyledSelect
                disabled={disabled}
                paddingRightInput='42px'
                placeholder='select a value'
                value={variant ?? ''}
                onChange={({ target }) => onVariantChange(String(target.value))}
              >
                {variants?.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          </>
        );
      case ValueEnum.NumberRange:
        return (
          <>
            <StyledInput
              disabled={disabled}
              key='number-range-start'
              type='number'
              value={value}
              onChange={({ target }) => onValueChange(String(target.value))}
            />
            <LabelContainer minWidth='14px'>
              <BottomAlignedLabel>
                <Translate>to</Translate>
              </BottomAlignedLabel>
            </LabelContainer>
            <StyledInput
              disabled={disabled}
              key='number-range-end'
              type='number'
              value={secondValue ?? ''}
              onChange={({ target }) => onValueChange(String(target.value), true)}
            />
            {variants?.length > 0 && (
              <StyledSelect
                disabled={disabled}
                paddingRightInput='42px'
                placeholder='select a value'
                value={variant ?? ''}
                onChange={({ target }) => onVariantChange(target.value)}
              >
                {variants?.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          </>
        );
      case ValueEnum.DateRange:
        return (
          <>
            <DatePicker
              allowPastDate
              date={value}
              disableClearDate
              disabled={disabled}
              key='date-range-start'
              placeholder='select a date'
              onDateChange={(newDate) => {
                const formattedDate = format(newDate, 'yyyy-MM-dd');
                onValueChange(formattedDate);
              }}
            />
            <LabelContainer minWidth='22px'>
              <BottomAlignedLabel>
                <Translate>and</Translate>
              </BottomAlignedLabel>
            </LabelContainer>
            <DatePicker
              allowPastDate
              date={secondValue ?? ''}
              disableClearDate
              disabled={disabled}
              key='date-range-end'
              placeholder='select a date'
              onDateChange={(newDate) => {
                const formattedDate = format(newDate, 'yyyy-MM-dd');
                onValueChange(formattedDate, true);
              }}
            />
          </>
        );
      case ValueEnum.StateLocation:
        return (
          <StateAndProvinceDropdown
            disabled={disabled}
            placeholder='select a state/province'
            sx={{ minWidth: '300px' }}
            value={value}
            onChange={(state) => {
              onValueChange(state);
            }}
          />
        );
      case ValueEnum.ProductCategory:
        return (
          <ProductCategoryDropdown
            disabled={disabled}
            values={value}
            onChange={(categories) => {
              // here we're setting the array to a string
              onValueChange(String(categories));
            }}
          />
        );
      case ValueEnum.CustomerType:
        return enableMultiSelectDropdowns ? (
          <CustomerTypeDropdown
            automationId={`query-builder_customer-type-multi-select-dropdown-${String(index)}`}
            disabled={disabled}
            values={value}
            onChange={(customerTypes) => {
              // here we're setting the array to a string
              onValueChange(String(customerTypes));
            }}
          />
        ) : (
          <CustomerTypeSingleSelectDropdown
            automationId={`query-builder_customer-type-dropdown-${String(index)}`}
            disabled={disabled}
            helpText='select a customer type'
            sx={{ minWidth: '217px' }}
            value={value}
            onChange={(customerType) => {
              onValueChange(String(customerType));
            }}
          />
        );
      case ValueEnum.Gender:
        return enableMultiSelectDropdowns ? (
          <GenderOptionDropdown
            disabled={disabled}
            values={value}
            onChange={(genderOptions) => {
              // here we're setting the array to a string
              onValueChange(String(genderOptions));
            }}
          />
        ) : (
          <StyledInput
            disabled={disabled}
            key='string'
            type='text'
            value={value}
            onChange={({ target }) => onValueChange(target.value)}
          />
        );
      case ValueEnum.String:
      default:
        return (
          <StyledInput
            disabled={disabled}
            key='string'
            type='text'
            value={value}
            onChange={({ target }) => onValueChange(target.value)}
          />
        );
    }
  };

  const selectedOperator = operators.find((op) => op.name === operator);

  return (
    <>
      <StyledSelect
        automationId={`query-builder_select-operator_select-${String(index)}`}
        disabled={disabled || selectedOperator?.valueType === ValueEnum.Boolean}
        paddingRightInput='42px'
        placeholder='select an option'
        value={operator ?? ''}
        onChange={({ target }) => onOperatorChange(target.value)}
      >
        {operators.map((operator) => (
          <MenuItem key={operator.name} value={operator.name}>
            {operator.label}
          </MenuItem>
        ))}
      </StyledSelect>
      {operator && <Value>{renderInput(selectedOperator)}</Value>}
    </>
  );
}
