import React from 'react';

import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Button } from 'src/app/components/lib/button';
import {
  ControlBar,
  ControlGroup,
  ControlMultiFilterDropdown,
  ControlSearchBox,
  RefreshButton,
} from 'src/app/components/lib/control-bar';
import { HeaderContainer } from 'src/app/components/lib/header-container';
import { Heading } from 'src/app/components/lib/heading';
import { Page, PageVariant } from 'src/app/components/lib/page';
import { DataGrid, useGridApiRef } from 'src/app/components/lib/table';
import { useBOMPermissions } from 'src/app/hooks/use-bom-permissions';
import { useAssembliesQuery } from 'src/app/queries/graphql/assemblies/get-many';

import {
  ASSEMBLY_LIST_PAGE_TITLE,
  ASSEMBLY_STATUS_OPTIONS,
  SEARCH_ASSEMBLIES_PLACEHOLDER,
  UrlParams,
} from '../constants';
import { filterArrayForValidNumbers } from '../utils';

import { useAssemblyColumns, useAssembliesServerTableControls } from './hooks';

import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';

export function AssembliesList() {
  const navigate = useNavigate();
  const { canEditAssemblies } = useBOMPermissions();

  const { getFilterValue, setFilterValue, tableProps, queryProps } = useAssembliesServerTableControls();
  // Fetch the Assemblies
  const { data: assemblies, isLoading, isFetching, refetch } = useAssembliesQuery(queryProps);
  const rows = assemblies?.items ?? [];
  const totalCount = assemblies?.totalCount ?? 0;
  const gridApiRef = useGridApiRef();
  const columns = useAssemblyColumns();

  const getSelectedOptions = (): DropdownMenuOption[] => {
    const rawStatusIds = getFilterValue(UrlParams.STATUS);
    const validStatusIds = filterArrayForValidNumbers(rawStatusIds);
    return ASSEMBLY_STATUS_OPTIONS.filter(({ id }) => validStatusIds.includes(Number(id)));
  };

  return (
    <Page variant={PageVariant.Table}>
      <HeaderContainer>
        <Heading>{ASSEMBLY_LIST_PAGE_TITLE}</Heading>
        <ControlGroup>
          <Button
            automationId='create-assembly-button'
            disabled={!canEditAssemblies}
            label='Create assembly'
            onClick={() => navigate('create')}
          />
        </ControlGroup>
      </HeaderContainer>
      <ControlBar>
        <ControlGroup grow>
          <ControlSearchBox
            initialValue={String(getFilterValue(UrlParams.SEARCH))}
            placeholder={SEARCH_ASSEMBLIES_PLACEHOLDER}
            onChange={debounce((searchTerm) => setFilterValue(UrlParams.SEARCH, searchTerm), 500)}
          />
          <ControlMultiFilterDropdown
            automationId='assembly-list-status-filter-button'
            filterId='status'
            label='Statuses'
            options={ASSEMBLY_STATUS_OPTIONS}
            selected={getSelectedOptions()}
            sortOptions={false}
            onChange={(options) => {
              setFilterValue(
                UrlParams.STATUS,
                options.map(({ id }) => id)
              );
            }}
            onReset={() => setFilterValue(UrlParams.STATUS, [0])}
          />
          <RefreshButton isFetching={isFetching} onClick={refetch} />
        </ControlGroup>
      </ControlBar>
      <DataGrid
        apiRef={gridApiRef}
        columns={columns}
        getRowId={(row) => row?.assemblyId}
        height='100%'
        loading={isLoading}
        rowCount={totalCount}
        rows={rows}
        onRowClick={({ row }) => navigate(`${String(row.assemblyId)}`)}
        {...tableProps}
      />
    </Page>
  );
}
