import { object, string } from 'yup';

import { getOperatorFromRule } from '../../utils';

import type { Attributes, CustomerSegmentSchemaMap } from './schema';
import type {
  Rule,
  RuleGroup,
} from 'src/app/pages/customers/customers/segments/details/segment-query-builder/use-query-builder';
import type { BaseSchema } from 'yup';

type getQueryBuilderSchemaVersionProps = {
  enableSegmentsV2: boolean;
  getYup: BaseSchema;
};

const getQueryBuilderSchemaVersion = ({ getYup, enableSegmentsV2 }: getQueryBuilderSchemaVersionProps) => {
  if (enableSegmentsV2) {
    return object({
      Attribute: string().required(),
      Condition: string().required(),
      Operator: string().required(),
      Value: getYup.required(),
      SecondValue: getYup
        .when('$requireSecondValue', {
          is: true,
          then: (schema) => schema.required(),
          otherwise: (schema) =>
            schema
              .transform((value, originalValue) => (originalValue === '' || originalValue === null ? undefined : value))
              .optional(),
        })
        .test({
          name: 'ValueLessThanSecondValue',
          test() {
            const { Value, SecondValue } = this.parent;

            if (Value && SecondValue) {
              return Value <= SecondValue;
            }
            return true;
          },
        }),
      Variant: string().when('$requireVariant', (requireVariant, schema) => {
        if (requireVariant) {
          return schema.required().nullable();
        }
        return schema.nullable();
      }),
    });
  }
  return object({
    Attribute: string().required(),
    Condition: string().required(),
    Operator: string().required(),
    Value: getYup.required(),
    SecondValue: getYup.when('$requireSecondValue', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .transform((value, originalValue) => (originalValue === '' || originalValue === null ? undefined : value))
          .optional(),
    }),
    Variant: string().when('$requireVariant', (requireVariant, schema) => {
      if (requireVariant) {
        return schema.required().nullable();
      }
      return schema.nullable();
    }),
  });
};

type SchemaForQueryBuilderProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  enableSegmentsV2: boolean;
  rule: Rule;
};

export const generateSchemaForQueryBuilder = ({
  rule,
  customerSegmentSchema,
  enableMultiSelectDropdowns,
  enableSegmentsV2,
}: SchemaForQueryBuilderProps) => {
  const getYup: BaseSchema =
    getOperatorFromRule({ rule, customerSegmentSchema, enableMultiSelectDropdowns })?.yup || string();

  const queryBuilderSchema = getQueryBuilderSchemaVersion({ getYup, enableSegmentsV2 });

  return queryBuilderSchema;
};

type IsRuleValidProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  enableSegmentsV2: boolean;
  requireSecondValue: boolean;
  requireVariant: boolean;
  rule: Rule;
};

export function isRuleValid({
  rule,
  customerSegmentSchema,
  requireVariant,
  requireSecondValue,
  enableMultiSelectDropdowns,
  enableSegmentsV2,
}: IsRuleValidProps) {
  try {
    generateSchemaForQueryBuilder({
      rule,
      customerSegmentSchema,
      enableMultiSelectDropdowns,
      enableSegmentsV2,
    }).validateSync(rule, {
      context: { requireSecondValue, requireVariant },
    });
    return true;
  } catch {
    return false;
  }
}

type ValidateRuleProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  enableSegmentsV2: boolean;
  rule: Rule;
};

export const validateRule = ({
  rule,
  customerSegmentSchema,
  enableMultiSelectDropdowns,
  enableSegmentsV2,
}: ValidateRuleProps) => {
  // only some rules require a second value or variant
  const getOperatorSecondValue =
    getOperatorFromRule({ rule, customerSegmentSchema, enableMultiSelectDropdowns })?.requiresSecondValue || false;
  const requireSecondValue = getOperatorSecondValue;
  const variants = customerSegmentSchema
    ? customerSegmentSchema[rule.Condition]?.attributes?.find(
        (attribute: Attributes) => attribute.name === rule.Attribute
      )?.variants
    : [];
  const requireVariant = variants?.length > 0;

  return isRuleValid({
    rule,
    customerSegmentSchema,
    requireVariant,
    requireSecondValue,
    enableMultiSelectDropdowns,
    enableSegmentsV2,
  });
};

type ValidateAllRulesProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  enableMultiSelectDropdowns: boolean;
  enableSegmentsV2: boolean;
  ruleGroup?: RuleGroup;
};

export const validateAllRules = ({
  ruleGroup,
  customerSegmentSchema,
  enableMultiSelectDropdowns,
  enableSegmentsV2,
}: ValidateAllRulesProps) => {
  const areRulesValid = ruleGroup?.rules.every((rule) =>
    validateRule({ rule, customerSegmentSchema, enableMultiSelectDropdowns, enableSegmentsV2 })
  );

  return areRulesValid;
};
