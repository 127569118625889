import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import _ from 'lodash';

import UserActions from 'src/app_deprecated/actions/UserActions';
import UserStore from 'src/app_deprecated/stores/UserStore';

import {
  FilterOperator,
  LogicalOperator,
  OneToManyFilterOperator,
} from 'src/app/queries/types/gql-filtering-and-pagination';
import { getRequestHeaders } from 'src/app/utils/request-headers';

import { ReactQueryError, RevokedSessionError } from '../utils';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { UseQueryOptions, QueryKey } from '@tanstack/react-query';
import type {
  AbridgedFilterInput,
  FilterModel,
  PaginationProps,
  QueryProps,
  Direction,
  BaseResponse,
} from 'src/app/queries/types/gql-filtering-and-pagination';

type WhereFilter<V> = Record<keyof V, unknown>;

type ClauseParts<V> = {
  [key in LogicalOperator]?: WhereFilter<V>[];
}[];

export type BaseRequestOmissions = 'locId' | 'lspId' | 'sessionId' | 'userId';

export type GraphQLMutationError = {
  response: {
    errors: {
      message: string;
    }[];
    status: number;
  };
};

/**
 * This function takes a filter model and converts it into a where clause that can be used to query a database.
 * It accepts an array of filter models as an argument.
 */
export function makeWhereClause<V>(filterModel?: FilterModel<V>[]) {
  if (!filterModel) {
    return undefined;
  }
  // Initialize the where clause array
  const whereClause: ClauseParts<V> = [];

  // Loop through the filter model
  filterModel
    ?.filter((filt) => filt.operator !== FilterOperator.VARIABLE)
    .forEach(({ logicalOperator, fields, value, operator, customValueToApi, multiValues, oneToManyFields }) => {
      if (multiValues && fields) {
        whereClause.push({
          [LogicalOperator.OR]: multiValues.map((multiValue) => {
            const filterObject = {};
            _.set(filterObject, fields[0], {
              [operator]: customValueToApi ? customValueToApi(multiValue) : multiValue,
            });
            return filterObject as WhereFilter<V>;
          }),
        });
      } else {
        // if the value is empty, null, or undefined, don't create a where clause for that field
        if (Array.isArray(value)) {
          // This was not doing anything. It was filtering for everything
          // const noEmpty = value.filter((val) => val !== '' || val !== null || val !== undefined);
          if (!value.length) {
            return;
          }
        }

        // if the value is empty, null, or undefined, don't create a where clause for that field
        const val = customValueToApi ? customValueToApi(value) : value;
        if (val === '' || val === undefined) {
          return;
        }

        // Create a filter object for each field
        const filterObjects: WhereFilter<V>[] = fields?.map((field) => {
          const filterObject = {};
          _.set(filterObject, field, { [operator]: customValueToApi ? customValueToApi(value) : value });
          return filterObject;
        }) as WhereFilter<V>[];

        // If we have oneToManyFields, add them to the filterObjects array
        oneToManyFields?.forEach((field) => {
          const filterObject = {};
          const applicableValue = customValueToApi ? customValueToApi(value) : value;
          _.set(filterObject, field.navigationField, {
            [field.operator]:
              field.operator === OneToManyFilterOperator.ALL
                ? applicableValue
                : { [field.manyField]: { [operator]: applicableValue } },
          });
          filterObjects.push(filterObject as WhereFilter<V>);
        });

        // If the logical operator is OR, add the filter objects to the where clause
        if (logicalOperator === LogicalOperator.OR && value !== undefined) {
          whereClause.push({ [LogicalOperator.OR]: filterObjects });
        }
        // If the logical operator is AND, add the filter objects to the where clause
        else if (logicalOperator === LogicalOperator.AND && value !== undefined) {
          whereClause.push({ [LogicalOperator.AND]: filterObjects });
        }
      }
    });

  // Return the where clause
  return { and: whereClause };
}
// This code will take a sort array of objects that has a key and direction property and will return an array of objects with each key and it's direction as the value. The sort array is an array of objects that has a key and direction property. The key property is a string that contains the name of the key to sort by. The direction property is a string that contains either "asc" or "desc" which is the direction to sort by.
export function makeOrderClause(sort) {
  if (!sort) {
    return undefined;
  }

  const orderClause: Record<string, Direction>[] = [];
  sort.forEach((sortItem) => {
    if (sortItem.direction && sortItem.direction.length > 0) {
      const orderItem = {};
      _.set(orderItem, sortItem.key, sortItem.direction.toUpperCase());
      orderClause.push(orderItem);
    }
  });

  return orderClause;
}

// This function takes a page number and a page size and returns an object with
// a skip and take property to be used by the pagination library.
export function makePaginationProps(page?: number, pageSize?: number): PaginationProps | undefined {
  if (page === undefined || pageSize === undefined) {
    return undefined;
  }

  return {
    skip: Number(page) * Number(pageSize),
    take: Number(pageSize),
  };
}

export function makeAdditionalVariables<V>(filterModel?: FilterModel<V>[]) {
  const variableFilterModels = filterModel?.filter((filt) => filt?.operator === FilterOperator.VARIABLE);
  const additionalVariables = {};

  variableFilterModels?.forEach((variableFilterModel) => {
    const { field, value } = variableFilterModel;
    additionalVariables[field] = value;
  });

  return additionalVariables;
}

/**
 *
 * @param gqlQueryDocument - GQL document must have its top-level fields aliased as "data" @see {'src/app/queries/graphql/cars/get-many.graphql'}
 *
 */
export async function fetchMany<QueryType extends BaseResponse<QueryType>, FilterInput, SortInput, QueryVariables>(
  gqlQueryDocument: TypedDocumentNode<QueryType, QueryVariables>,
  queryProps?: QueryProps<AbridgedFilterInput<FilterInput>, SortInput>,
  additionalVariables?: { key: string; value: unknown }[]
) {
  const userApiPayload = UserStore.getApiData();
  const {
    conditionalFields = {},
    filterModel,
    tableName,
    pageSize,
    page,
    sort,
    applyUserId = false,
  } = queryProps || {};
  const whereClause = makeWhereClause(filterModel);
  const orderClause = makeOrderClause(sort);
  const paginationProps = makePaginationProps(page, pageSize);
  const additionalVariablesFromFilterModel = makeAdditionalVariables(filterModel);

  type RequestVariables = Partial<PaginationProps> & {
    locId: number;
    lspId: number;
    orderClause: Record<string, Direction>[] | undefined;
    whereClause: { and: ClauseParts<AbridgedFilterInput<FilterInput>> } | undefined;
  };
  try {
    const graphQLClient = new GraphQLClient('/api/graphql', { fetch, headers: getRequestHeaders() });
    const response = await graphQLClient.request<QueryType, RequestVariables>(gqlQueryDocument, {
      lspId: userApiPayload.LspId,
      locId: userApiPayload.LocId,
      ...(applyUserId && { userId: userApiPayload.UserId }),
      orderClause,
      whereClause,
      ...paginationProps,
      ...conditionalFields,
      ...additionalVariablesFromFilterModel,
      ...additionalVariables?.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {}),
    });

    return response.data;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      throw new RevokedSessionError();
    }
    const errors = e?.response?.errors;
    const badTypeError = errors?.filter((err) => err?.message?.includes('the type'));
    if (badTypeError.length) {
      // the user shouldn't be able to get here. If they do, lets attempt to bail them out.
      // first clear any saved settings for the table they are currently looking at
      if (tableName) {
        const currentSettings = UserStore.getTableSettings(tableName);
        if (currentSettings?.layout?.length) {
          const newLayoutSettings = [...currentSettings.layout].map((setting) => {
            if (setting.sort) {
              return { ...setting, sort: { ...setting.sort, sort: null } };
            }
            return setting;
          });
          await UserActions.setTableSettings(tableName, newLayoutSettings, true);
        }
        // then remove url params--if incorrect params were causing the issue, we don't want them to be present if the user tries to refresh
        window.history.pushState(null, '', window.location.pathname);
        sessionStorage.setItem(tableName, '');
        window.location.reload();
        throw new Error(e);
      }
    }
    return null;
  }
}

export type FetchOneParameters = {
  applyUserId?: boolean;
  conditionalFields?: Record<string, boolean>;
  error?: {
    message: string;
    navigateOnErrorCallback?: () => void;
    sticky?: boolean;
  };
  key: string;
  record?: string;
  value: number | string;
};

/**
 *
 * @param gqlQueryDocument - GQL document must have its top-level fields aliased as "data" @see {'src/app/queries/graphql/cars/get-one.graphql'}
 *
 */
export async function fetchOne<QueryType extends BaseResponse<QueryType>, QueryVariables>(
  gqlQueryDocument: TypedDocumentNode<QueryType, QueryVariables>,
  { key, value, record = 'record', applyUserId = false, conditionalFields = {}, error }: FetchOneParameters
) {
  const userApiPayload = UserStore.getApiData();

  type RequestVariables = {
    locId: number;
    lspId: number;
  };
  const graphQLClient = new GraphQLClient('/api/graphql', { fetch, headers: getRequestHeaders() });
  try {
    const response = await graphQLClient.request<QueryType, RequestVariables>(gqlQueryDocument, {
      lspId: userApiPayload.LspId,
      locId: userApiPayload.LocId,
      ...(applyUserId && { userId: userApiPayload.UserId }),
      [key]: value,
      ...conditionalFields,
    });
    if (response.data === null || response.data === undefined) {
      if (!error) {
        throw new Error(`The ${record} you were looking for could not be found at ID: ${value}`, {
          cause: 'client-error',
        });
      } else if (error) {
        error.navigateOnErrorCallback?.();
        throw new ReactQueryError({ args: { message: error.message, sticky: error.sticky } });
      }
    }

    return response.data;
  } catch (e: unknown) {
    const error = e as { cause?: string; message?: string; response?: { status: number } } | undefined;
    if (error?.response?.status === 401) {
      throw new RevokedSessionError();
    }
    if (error?.cause === 'client-error') {
      throw new Error(error?.message, { cause: 'client-error' });
    }
    return null;
  }
}

export async function mutate<MutationType, MutationVariables>(
  gqlMutationDocument: TypedDocumentNode<MutationType, MutationVariables>,
  mutationVariables: MutationVariables extends Record<string, unknown> ? MutationVariables : never
) {
  const graphQLClient = new GraphQLClient('/api/graphql', { fetch, headers: getRequestHeaders() });
  try {
    const response = await graphQLClient.request<MutationType, Record<string, unknown>>(
      gqlMutationDocument,
      mutationVariables
    );
    return response;
  } catch (e: any) {
    if (e?.response?.status === 401) {
      throw new RevokedSessionError();
    }
    return null;
  }
}

export function aggregatePages<TItem>(pagesData: { pages?: Array<{ items?: (TItem | undefined)[] }> }) {
  const aggregatedData: (TItem | undefined)[] = [];

  pagesData?.pages?.forEach((page) => {
    if (page.items) {
      aggregatedData.push(...page.items);
    }
  });

  return aggregatedData;
}

export type UseQueryOptionsForUseQueries = Omit<UseQueryOptions, 'context'>;
export const totalExportRequestCountLimit = 4; // limit the total number of pages we allow to be exported. Based on the below limit of 2500 items per request, this means we can export 10k items at a time.
export const paginatedExportRequestCountLimit = 2500; // limit count of each request to 2500 items.
export function paginatedExportNumberOfRequests(totalCount: number) {
  return Math.ceil(totalCount / paginatedExportRequestCountLimit);
}
// export const paginatedExportNumberOfRequests = (totalCount: number) =>
//   Math.ceil(totalCount / paginatedExportRequestCountLimit);
export const paginatedExportPageProps = (i: number) => ({
  page: i,
  pageSize: paginatedExportRequestCountLimit,
});
export const paginatedExportCommonQueryProps: Partial<UseQueryOptions> = {
  enabled: false,
  retry: false,
  retryOnMount: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  staleTime: 30 * 1000,
};

type ItemTypeExtractor<TItem = unknown> = {
  data?: { items?: TItem[] | null; pageInfo?: { hasNextPage?: boolean } } | null;
};
type GQLResponseWithItems<TItem> = ItemTypeExtractor<TItem>;

type UseInfiniteGQLQueryParams<TFilter, TSort, QueryVariables, TItem> = {
  enabled?: boolean;
  queryDocument: TypedDocumentNode<GQLResponseWithItems<TItem>, QueryVariables>;
  queryKey: QueryKey;
  queryProps: QueryProps<AbridgedFilterInput<TFilter>, TSort>;
};

type QueryKeyElement = Record<string, any> | number | string;

export const maxPageSize = 10000;

function modifyQueryPageSize(queryKey: QueryKey): QueryKey {
  return (queryKey as QueryKeyElement[]).map((key) => {
    if (typeof key === 'object' && 'pageSize' in key) {
      return { ...key, pageSize: maxPageSize };
    }
    return key;
  });
}

function modifyQueryPropsPageSize(queryProps) {
  return {
    ...queryProps,
    pageSize: maxPageSize,
  };
}

export function useInfiniteGQLQuery<TFilter, TSort, QueryVariables, TItem>({
  queryKey,
  queryProps,
  queryDocument,
  enabled = true,
}: UseInfiniteGQLQueryParams<TFilter, TSort, QueryVariables, TItem>) {
  const queryClient = useQueryClient();
  const customQueryFunction =
    (props) =>
    async ({ pageParam }: { pageParam?: number }) => {
      const data = await fetchMany(queryDocument, {
        ...props,
        page: pageParam ?? 0,
      });
      const items = data?.items?.filter(Boolean);
      return { pageInfo: data?.pageInfo, items };
    };

  async function fetchAllItems() {
    return queryClient.fetchInfiniteQuery({
      queryKey: modifyQueryPageSize(queryKey),
      queryFn: customQueryFunction(modifyQueryPropsPageSize(queryProps)),
    });
  }

  const infiniteQueryResult = useInfiniteQuery({
    queryKey,
    queryFn: customQueryFunction(queryProps),
    getNextPageParam: (_previousPage, allPages) => allPages.length, // allPages is an array of pages--so .length represents the qty of pages we already have + 1. Ideally the API response (the data represented by _previousPage) would have a `page` field, but at present it does not
    keepPreviousData: true,
    refetchOnWindowFocus: false, // these queries are typically used in dropdowns. In those cases the data does not change enough to warrant additional load
    enabled,
  });
  const currentPage = infiniteQueryResult.data?.pages[infiniteQueryResult.data.pages.length - 1];
  const morePagesExist = currentPage?.pageInfo?.hasNextPage ?? false;

  return {
    ...infiniteQueryResult,
    morePagesExist,
    data: infiniteQueryResult?.data ? aggregatePages(infiniteQueryResult?.data) : [],
    fetchAllItems,
  };
}
