import { useCallback, useEffect, useState } from 'react';

import { noop } from 'lodash';
// import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import { useTabsWithQueryParams } from 'src/app/hooks/use-tabs-with-query-params';
import { useYupFormValidation } from 'src/app/hooks/use-yup-field-validation';
import { TABS } from 'src/app/pages/manufacturing/shared/constants';
// import { routes } from 'src/app/routes';
// import { useNotificationActions } from 'src/app/state/notifications';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import { AssemblyDetails } from './form/detail';
import { AssemblyDocuments } from './form/documents';
import { AssemblyProcessingJob } from './form/processing-jobs';
import { assemblySchema } from './schema';
import { useAssemblyFormStateContext } from './use-form-state';

import type { AssemblyDetailFormProps } from '.';

export const useAssemblyForm = ({ formType, assemblyData }: AssemblyDetailFormProps) => {
  // Recoil
  const showBlockNavigationModal = useLDFlag(LD.CREATE_BROWSER_ROUTER_ROLLOUT, false);
  const { integrations } = useRecoilValue(userDispensariesAtom);
  const { UseMetrcV2, UseMetrcProcessingJobs } = integrations ?? {};

  const canViewProcessingJobs = UseMetrcV2 && UseMetrcProcessingJobs;
  // Constants
  const tabs = [
    { label: TABS.details.label, queryParam: TABS.details.queryParam, component: AssemblyDetails },
    { label: TABS.documents.label, queryParam: TABS.documents.queryParam, component: AssemblyDocuments },
    ...(canViewProcessingJobs
      ? [
          {
            label: TABS.processingJobs.label,
            queryParam: TABS.processingJobs.queryParam,
            component: AssemblyProcessingJob,
          },
        ]
      : []),
  ];
  // Hooks
  // Uncomment when implementing Create/Update Mutations
  //   const { assemblyId } = useParams();
  //   const notifications = useNotificationActions();
  //   const navigate = useNavigate();
  //   const navigateBackToListPage = () => navigate(routes.manufacturing.assemblies);
  const { activeTab, setActiveTab } = useTabsWithQueryParams(tabs.map((t) => t.queryParam));

  const [{ formData, context, isDirty, isReadonly }, dispatch] = useAssemblyFormStateContext();

  const onValidation = useCallback((errors) => dispatch({ type: 'set-errors', payload: errors }), [dispatch]);

  const { isValid } = useYupFormValidation({ schema: assemblySchema, formData, onValidation, context });
  // State
  const [isSaving, _setIsSaving] = useState(false);
  // TODO: Add Create/Update Mutations
  // Derived state
  const isEditing = formType === 'edit';
  const isLoading = isSaving; // TODO: add isCreatingAssembly || isUpdatingAssembly once mutations are defined
  // Initialize state when editing
  useEffect(() => {
    if (isEditing && assemblyData) {
      dispatch({ type: 'initialize-edit-assembly-state', payload: assemblyData });
    }
  }, [isEditing, assemblyData, dispatch]);
  // Set form context
  useEffect(() => {
    dispatch({ type: 'set-context', payload: { useMetrcV2: UseMetrcV2 } });
  }, [dispatch, UseMetrcV2]);

  const onSubmit = () => noop();
  // TODO: Uncomment this once create/update mutations are defined
  //   const onSubmit = () => {
  //     // Set loading state
  //     setIsSaving(true);
  //     const action = isEditing ? 'updated' : 'created';
  //     // Mutation Callbacks
  //     const onSuccess = () => {
  //       notifications.success({ message: `Assembly has been successfully ${action}` });
  //       dispatch({ type: 'set-is-dirty', payload: false });
  //       navigateBackToListPage();
  //     };
  //     const onError = (error) => {
  //       const message = error?.response?.data?.Message;
  //       const defaultMessage = `Failed to ${action.slice(0, -1)} Assembly`;
  //       notifications.error({ message: message || defaultMessage });
  //     };

  //     const onSettled = () => setIsSaving(false);
  //     // Call Mutations
  //     if (isEditing) {
  //       const payload = mapSchemaToUpdatePayload(formData, Number(assemblyId));
  //       updateAssembly(payload, { onSuccess, onError, onSettled });
  //     } else {
  //       const payload = mapSchemaToCreatePayload(formData);
  //       createAssembly(payload, { onSuccess, onError, onSettled });
  //     }
  //   };

  return {
    activeTab,
    showBlockNavigationModal,
    setActiveTab,
    onSubmit,
    tabs,
    isValid,
    isDirty,
    isLoading,
    isReadonly,
  };
};
