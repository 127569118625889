import React from 'react';

import { PROCESSING_JOB_INFO } from 'src/app/pages/manufacturing/assemblies/constants';
import { ProcessingJobForm } from 'src/app/pages/manufacturing/shared/components/processing-job-form';

import { useAssemblyFormStateContext } from '../../use-form-state';

export function AssemblyProcessingJob() {
  const [
    {
      formData: { processingJobTypeTemplate: formValues },
      errors,
    },
  ] = useAssemblyFormStateContext();
  return <ProcessingJobForm alertBannerText={PROCESSING_JOB_INFO} errors={errors} formValues={formValues} isReadonly />;
}
