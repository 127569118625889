import React, { useMemo, useState } from 'react';

import { Stack } from '@mui/material';
import {
  addDays,
  differenceInCalendarDays,
  format,
  getMonth,
  startOfToday,
  getDaysInMonth,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subDays,
} from 'date-fns';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

import { ContentCard } from 'src/app/components/lib/content-card';
import {
  DateRangeOption,
  GetOptionFromRange,
  NameOfMonth,
} from 'src/app/components/lib/date-picker/date-picker-range/date-picker-range';
import { Loader } from 'src/app/components/lib/loader';
import { NoResultsOverlay } from 'src/app/components/lib/table/no-results-overlay';
import { Tooltip, TooltipIcon, TooltipIconContainer } from 'src/app/components/lib/tooltip';
import { ContentCardTile } from 'src/app/pages/reports-v2/inventory/components/content-card-tile';

import { getCssVarValueFromName } from './utils/styles-helpers';
import { formatCurrency } from './utils/utils';

import type { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internals/models';
import type {
  ReportsSummary,
  ReportsSummaryOverview,
} from 'src/app/pages/customers/customers/segments/details/reports/utils/types';

type ReportsSnapshotSummaryProps = {
  date: NonEmptyDateRange<Date>;
  isLoading?: boolean;
  reportSummary: ReportsSummaryOverview;
};

export const GetLabelForGroupingOption = (option: ValueGroupingOption): string => {
  switch (option) {
    case ValueGroupingOption.AOV:
      return 'AOV';
    case ValueGroupingOption.AvgItemsPerCart:
      return 'Avg # of items per cart';
    case ValueGroupingOption.TransactionCount:
      return '# Transactions';
    case ValueGroupingOption.TotalSpend:
      return 'Total spend';
  }
  return 'Unknown';
};
export enum ValueGroupingOption {
  AOV = 'AOV',
  AvgItemsPerCart = 'AvgItemsPerCart',
  TotalSpend = 'TotalSpend',
  TransactionCount = 'TransactionCount',
}

export function ReportsSnapshotSummary({ reportSummary, isLoading, date }: ReportsSnapshotSummaryProps) {
  const { AllSales, SegmentSales } = reportSummary;

  // State Objects
  const [valueGroupingOption, setValueGroupingOption] = useState(ValueGroupingOption.AOV);
  const [currentBar, setCurrentBar] = useState<string | undefined>();
  const [currentSummaries, setCurrentSummaries] = useState<Record<string, number>>({
    AOV: 0,
    Date: 0,
    AvgItemsPerCart: 0,
    TransactionCount: 0,
    TotalSpend: 0,
  });

  // Summary Data Functions
  const summedSummaries = (totals: any) => {
    const startingSums: Record<string, number> = {
      AOV: 0,
      Date: 0,
      AvgItemsPerCart: 0,
      TransactionCount: 0,
      TotalSpend: 0,
      TotalItemsSold: 0,
    };

    const totalsToUse = totals || SegmentSales;
    let monthlySums = totalsToUse.reduce((periodSums, currentSummary) => {
      periodSums.TotalSpend += currentSummary.TotalSpend;
      periodSums.TransactionCount += currentSummary.TransactionCount;
      periodSums.TotalItemsSold += currentSummary.AvgItemsPerCart * currentSummary.TransactionCount;

      return periodSums;
    }, startingSums);

    const totalTransactionCount = monthlySums.TransactionCount;
    monthlySums = {
      AOV: totalTransactionCount ? monthlySums.TotalSpend / totalTransactionCount : 0,
      AvgItemsPerCart: totalTransactionCount ? monthlySums.TotalItemsSold / totalTransactionCount : 0,
      TransactionCount: totalTransactionCount,
      TotalSpend: monthlySums.TotalSpend,
    };

    return monthlySums;
  };

  // Date and label functions
  const dateOption = useMemo(() => GetOptionFromRange(date), [date]);

  const getDateLabels = useMemo(() => {
    const months = Object.values(NameOfMonth);
    let dateLabels: string[] = [];
    const startOfTodayDate = startOfToday();

    const getCustomDayDateLabels = (periodEndDate, numDays, startFromToday) => {
      const dateLabels: string[] = [];
      for (let day = numDays; day >= periodEndDate; day--) {
        const dayDate = startFromToday ? subDays(startOfTodayDate, day) : subDays(date[1], day);
        dateLabels.push(format(dayDate, 'MMM d'));
      }
      return dateLabels;
    };

    switch (dateOption) {
      case DateRangeOption.Yesterday: {
        return ['Yesterday'];
      }
      case DateRangeOption.LastQuarter: {
        const nowDate = new Date();
        const lastQuarter = subQuarters(nowDate, 1);
        const startOfQuarterDate = startOfQuarter(lastQuarter);
        const endOfQuarterDate = endOfQuarter(lastQuarter);

        return [
          NameOfMonth[getMonth(startOfQuarterDate)],
          NameOfMonth[getMonth(startOfQuarterDate) + 1],
          NameOfMonth[getMonth(endOfQuarterDate)],
        ];
      }
      case DateRangeOption.Last7Days: {
        dateLabels = getCustomDayDateLabels(1, 7, true);
        return dateLabels;
      }
      case DateRangeOption.LastMonth: {
        const daysInMonth = getDaysInMonth(getMonth(date[1]));
        dateLabels = getCustomDayDateLabels(1, daysInMonth, true);
        return dateLabels;
      }
      case DateRangeOption.Custom: {
        const iteratorStart = 0;
        const iteratorEnd = differenceInCalendarDays(date[1], date[0]);

        if (iteratorEnd < 31) {
          return getCustomDayDateLabels(iteratorStart, iteratorEnd, false);
        }

        const startOfCustomMonthRange = getMonth(date[0]) + 1;
        const endOfCustomMonthRange = getMonth(date[1]) + 2;

        if (startOfCustomMonthRange < endOfCustomMonthRange) {
          for (let month = startOfCustomMonthRange; month < endOfCustomMonthRange; month++) {
            dateLabels.push(NameOfMonth[month]);
          }
        }
        return dateLabels;
      }
      case DateRangeOption.YTD:
        return months;
      case DateRangeOption.LastYear:
        return months;
      default:
        return ['Yesterday'];
    }
  }, [dateOption, date]);

  const groupedByInterval = (salesData) => {
    // Find option
    if (!salesData) {
      return [];
    }
    const iteratorEnd = differenceInCalendarDays(date[1], date[0]);

    if (
      (iteratorEnd > 31 && dateOption === DateRangeOption.Custom) ||
      dateOption === DateRangeOption.YTD ||
      dateOption === DateRangeOption.LastQuarter ||
      dateOption === DateRangeOption.LastYear
    ) {
      const groupedByMonth = salesData.reduce((x, y) => {
        (x[`${new Date(y.Date).getMonth()}-${new Date(y.Date).getFullYear()}`] =
          x[`${new Date(y.Date).getMonth()}-${new Date(y.Date).getFullYear()}`] || []).push(y);

        return x;
      }, {});

      return groupedByMonth;
    }

    const groupedByDay = salesData.reduce((x, y) => {
      const currentDate = new Date(y.Date).toISOString().slice(0, 10);
      (x[currentDate] = x[currentDate] || []).push(y);

      return x;
    }, {});

    return groupedByDay;
  };

  const segmentCustomerDataGroupedByInterval: Partial<Record<number | string, ReportsSummary[]>> = useMemo(
    () => groupedByInterval(SegmentSales),
    [dateOption, date, SegmentSales]
  );
  const allCustomerDataGroupedByInterval: Partial<Record<number | string, ReportsSummary[]>> = useMemo(
    () => groupedByInterval(AllSales),
    [dateOption, date, AllSales]
  );

  // Segment customer and customer data filter functions
  const getFilteredCustomerValues = // useMemo(
    (customerData, shouldSaveSummary) => {
      const values: { locData: ReportsSummary[]; y: number }[] = [];
      const summaryData = [] as ReportsSummary[];

      if (customerData && reportSummary) {
        let iteratorStart = 0;
        let iteratorEnd = 11;
        const groupByDay =
          dateOption !== DateRangeOption.YTD &&
          dateOption !== DateRangeOption.LastYear &&
          dateOption !== DateRangeOption.LastQuarter;

        if (dateOption === DateRangeOption.Custom || groupByDay) {
          iteratorStart = 0;
          iteratorEnd = differenceInCalendarDays(date[1], date[0]);
        }
        if (dateOption === DateRangeOption.LastQuarter) {
          iteratorStart = getMonth(date[0]);
          iteratorEnd = getMonth(date[1]);
        }

        for (let i = iteratorStart; i <= iteratorEnd; i++) {
          let group;
          if (groupByDay && iteratorEnd < 31) {
            const day = new Date(addDays(date[0], i)).toISOString().slice(0, 10);

            group = customerData[day];
          } else {
            group = customerData[`${i}-${new Date(date[0]).getFullYear()}`];
          }

          if (group) {
            const groupValue = group.map((obj) => obj[valueGroupingOption]).reduce((acc, cur) => (acc += cur));
            values.push({
              y:
                valueGroupingOption === ValueGroupingOption.AvgItemsPerCart
                  ? Math.ceil(groupValue / group.length)
                  : groupValue,
              locData: group,
            });

            summaryData.push(group);
          } else {
            values.push({ y: 0, locData: [] });
          }
        }
      }

      if (shouldSaveSummary) {
        const summaries = summedSummaries(summaryData.flat());
        setCurrentSummaries(summaries);
      }

      return values;
    };

  const customerValues = useMemo(
    () => getFilteredCustomerValues(allCustomerDataGroupedByInterval, false),
    [allCustomerDataGroupedByInterval, date, dateOption, valueGroupingOption, reportSummary]
  );
  const segmentCustomerValues = useMemo(
    () => getFilteredCustomerValues(segmentCustomerDataGroupedByInterval, true),
    [segmentCustomerDataGroupedByInterval, date, dateOption, valueGroupingOption, reportSummary]
  );

  // Bar Graph Functions
  const graphOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      hover: {
        onHover(_evt: any, item: any[] | string) {
          if (item.length) {
            const chartInfo = item[0];
            const labels = [] as string[];
            const dataCount = chartInfo._chart.data.datasets[0].data;
            const currentLabel = GetLabelForGroupingOption(valueGroupingOption);

            for (const _ of dataCount) {
              labels.push(currentLabel);
            }

            for (const bar of item) {
              const chartBarInfo = bar;

              chartBarInfo._chart.tooltip._options.backgroundColor = '#fff';
              chartBarInfo._chart.tooltip._options.bodyFontColor = '#000';
              chartBarInfo._chart.tooltip._options.titleFontColor = '#000';
              chartBarInfo._chart.tooltip._data.labels = labels;
            }
          }
        },
      },
      legend: {
        display: true,
        position: 'bottom',
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            display: true,
          },
        ],
      },
    }),
    [currentBar]
  );

  const barGraphData = {
    labels: getDateLabels,
    datasets: [
      {
        label: 'Segment customers',
        hoverBackgroundColor: getCssVarValueFromName('--color-purple-50'),
        backgroundColor: getCssVarValueFromName('--color-purple-50'),
        data: segmentCustomerValues,
      },
      {
        label: 'All customers',
        hoverBackgroundColor: getCssVarValueFromName('--color-orange-50'),
        backgroundColor: getCssVarValueFromName('--color-orange-50'),
        data: customerValues,
      },
    ],
  };

  const tooltip = (
    <Tooltip placement='top' title='Includes selected customer.'>
      <TooltipIconContainer>
        <TooltipIcon />
      </TooltipIconContainer>
    </Tooltip>
  );

  return (
    <ContentCard
      addContentPadding={false}
      automationId='segments-overview'
      title='Segments Overview'
      titleAdornment={tooltip}
    >
      {isLoading && (
        <StyledWrapper>
          <Loader size='3x' variant='grey' />
        </StyledWrapper>
      )}
      {!isLoading && (!SegmentSales || SegmentSales.length === 0) && <StyledNoResultsOverlay />}
      {!isLoading && SegmentSales && SegmentSales.length !== 0 && (
        <>
          <CardStack direction='row' spacing={3}>
            <ContentCardTile
              content={formatCurrency(currentSummaries.AOV)}
              label={GetLabelForGroupingOption(ValueGroupingOption.AOV)}
              selected={valueGroupingOption === ValueGroupingOption.AOV}
              tooltip=''
              onClick={() => setValueGroupingOption(ValueGroupingOption.AOV)}
            />
            <ContentCardTile
              content={Math.ceil(currentSummaries.AvgItemsPerCart).toString()}
              label={GetLabelForGroupingOption(ValueGroupingOption.AvgItemsPerCart)}
              selected={valueGroupingOption === ValueGroupingOption.AvgItemsPerCart}
              tooltip=''
              onClick={() => setValueGroupingOption(ValueGroupingOption.AvgItemsPerCart)}
            />
            <ContentCardTile
              content={Math.ceil(currentSummaries.TransactionCount).toString()}
              label={GetLabelForGroupingOption(ValueGroupingOption.TransactionCount)}
              selected={valueGroupingOption === ValueGroupingOption.TransactionCount}
              tooltip=''
              onClick={() => setValueGroupingOption(ValueGroupingOption.TransactionCount)}
            />
            <ContentCardTile
              content={formatCurrency(currentSummaries.TotalSpend)}
              label={GetLabelForGroupingOption(ValueGroupingOption.TotalSpend)}
              selected={valueGroupingOption === ValueGroupingOption.TotalSpend}
              tooltip=''
              onClick={() => setValueGroupingOption(ValueGroupingOption.TotalSpend)}
            />
          </CardStack>

          <StyledContent
            onMouseLeave={() => {
              setCurrentBar(undefined);
            }}
          >
            <Bar data={barGraphData} height={355} options={graphOptions} width={1200} />
          </StyledContent>
        </>
      )}
    </ContentCard>
  );
}

const StyledContent = styled.div`
  display: flex;
  z-index: -999;
  padding: 24;
`;

const CardStack = styled(Stack)`
  padding: 16px;
  border-bottom: 1px solid var(--color-gray-20);
`;

const StyledNoResultsOverlay = styled(NoResultsOverlay)`
  position: static;
`;

const StyledWrapper = styled.div`
  background-color: var(--color-brand-primary-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--sizes-70);
  width: 100%;
  height: 300px;
`;
