import { useQuery } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import { reportDetailKeys, reportDemoDetailKeys } from './query-key-factory';

import type { QueryKey } from '@tanstack/react-query';
import type { ReportsSummaryOverview } from 'src/app/pages/customers/customers/segments/details/reports/utils/types';

type SegmentPayload = {
  CustomerTypes?: any[];
  FromDate?: Date;
  Locations?: any[];
  segmentId?: number;
  ToDate?: Date;
};

export const GET_SEGMENT_REPORT = 'api/segment/overview';
export const segmentsReportQueryKey: QueryKey = ['segmentsReport'];

export function useGetSegmentReportQuery(payload: SegmentPayload) {
  return useQuery({
    queryKey: reportDetailKeys.one(Number(payload.segmentId)),
    queryFn: () => postData<SegmentPayload, ReportsSummaryOverview>({ endpoint: GET_SEGMENT_REPORT, payload }),
  });
}

export function useGetSegmentReportDemoDataQuery(payload: SegmentPayload) {
  return useQuery({
    queryKey: reportDemoDetailKeys.one(Number(payload?.segmentId)),
    queryFn: () => getResponseDataII(),
  });
}

const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const getResponseDataII = () => {
  const dailyTotals = {
    AllSales: [],
    SegmentSales: [],
  } as ReportsSummaryOverview;

  for (let month = 1; month < 13; month++) {
    for (let day = 1; day < 31; day++) {
      const dayValue = {
        Date: `${month}/${day}/2024`,
        AOV: generateRandomNumber(20, 50),
        AvgItemsPerCart: generateRandomNumber(5, 20),
        TransactionCount: generateRandomNumber(100, 500),
        TotalSpend: generateRandomNumber(10, 500),
      };
      dailyTotals.AllSales.push(dayValue);
      dailyTotals.SegmentSales.push(dayValue);
    }
  }

  return (
    dailyTotals ?? {
      AllSales: [],
      SegmentSales: [],
    }
  );
};
