import { v4 as uuidv4 } from 'uuid';

import type { AssemblyFormState } from './detail/use-form-state';
import type { InputItem, InputPackage, OutputItem } from './types';
import type { DocumentSchema, ProcessingJobTypeTemplateSchema } from '../shared/schema';
import type {
  NonNullableAssemblyDocument,
  ProcessingJobTemplate,
  NonNullableAssemblyOutputItems,
  NonNullableAssemblyInputItems,
  NonNullableAssemblyInputPackages,
} from 'src/app/queries/graphql/assemblies/factory';
import type { AssemblyDetailResponse } from 'src/app/queries/graphql/assemblies/get-one';

export const DEFAULT_ASSEMBLY_STATE: AssemblyFormState = {
  billOfMaterialsId: null,
  estimatedStartDate: null,
  documents: [],
  name: '',
  outputs: [],
  processingJobTypeTemplate: {
    isProcessingJob: false,
    id: null,
    name: '',
    attributes: [],
    categoryName: '',
    description: '',
    processingSteps: '',
  },
};

export const filterArrayForValidNumbers = (values: unknown): number[] =>
  Array.isArray(values) ? values.filter((value) => !Number.isNaN(Number(value))).map(Number) : [];

export const buildAvailableQtyString = (quantity: number, unitAbbreviation: string) =>
  `${quantity.toString()} ${unitAbbreviation}`;

export const initializeDefaultAssemblyState = (): AssemblyFormState => DEFAULT_ASSEMBLY_STATE;

export const initializeEditAssemblyState = (values: AssemblyDetailResponse): AssemblyFormState => ({
  billOfMaterialsId: values?.billOfMaterials?.billOfMaterialsId ?? null,
  estimatedStartDate: values?.estimatedStartDate ?? null,
  documents: mapAssemblyDocumentsToForm(values?.billOfMaterials?.billOfMaterialsFiles?.filter((f) => !!f) ?? []),
  name: values?.name ?? '',
  outputs: mapAssemblyOutputItemsToForm(values?.assemblyOutputItems ?? []),
  processingJobTypeTemplate: mapProcessingJobTypeTemplateToForm(
    values?.billOfMaterials?.metrcProcessingJobTypeTemplate,
    !!values?.billOfMaterials?.isProcessingJob
  ),
});

export const mapAssemblyDocumentsToForm = (documents: NonNullableAssemblyDocument[]): DocumentSchema[] =>
  documents.map((file) => ({
    id: file?.billOfMaterialsFileId ?? null,
    uuid: uuidv4(),
    documentName: file?.documentName ?? '',
    documentTypeId: file?.documentTypeId ?? 1,
    fileName: file?.fileName ?? null,
    url: file?.url ?? null,
    fileUrl: file?.fileUrl ?? null,
    userFileName: file?.userFileName ?? null,
  }));

export const mapProcessingJobTypeTemplateToForm = (
  metrcProcessingJobTypeTemplate: ProcessingJobTemplate,
  isProcessingJob: boolean
): ProcessingJobTypeTemplateSchema => ({
  isProcessingJob,
  id: metrcProcessingJobTypeTemplate?.metrcProcessingJobTypeTemplateId ?? null,
  name: metrcProcessingJobTypeTemplate?.name ?? '',
  attributes: metrcProcessingJobTypeTemplate?.metrcProcessingJobTypeAttributes?.map((attr) => attr?.name ?? '') || [],
  categoryName: metrcProcessingJobTypeTemplate?.categoryName ?? '',
  description: metrcProcessingJobTypeTemplate?.description ?? '',
  processingSteps: metrcProcessingJobTypeTemplate?.processingSteps ?? '',
});

export const mapAssemblyOutputItemsToForm = (outputItems: NonNullableAssemblyOutputItems): OutputItem[] =>
  outputItems.map((output) => ({
    assemblyOutputItemId: output?.assemblyOutputItemId ?? null,
    batchId: output?.batchId ?? null,
    bypassStateSystem: !!output?.bypassStateSystem,
    cost: output?.cost ?? null,
    expirationDateUtc: output?.expirationDateUtc ?? null,
    inputItems: mapAssemblyInputItemsToForm(output?.assemblyInputItems ?? []),
    inventoryStatusId: output?.inventoryStatusId ?? null,
    inventoryTags: filterArrayForValidNumbers(output?.tags?.map((tag) => tag?.tagId)),
    isProductionBatch: !!output?.isProductionBatch,
    outputItemTypeId: output?.outputItemTypeId,
    packageDateUtc: output?.packageDateUtc ?? null,
    productId: output?.product?.id ?? null,
    productName: output?.product?.whseProductsDescription ?? '',
    productTypeId: output?.productType?.id ?? null,
    productTypeName: output?.productType?.productType ?? '',
    quantity: output?.quantity ?? null,
    roomId: output?.roomId ?? null,
    serialNumber: output?.serialNumber ?? '',
    skip: !!output?.skip,
    sourceSerialNumber: output?.sourceSerialNumber ?? '',
    unitId: output?.unit?.unitTypeId ?? null,
    unitAbbreviation: output?.unit?.abbreviation ?? '',
    useByDate: output?.useByDate ?? null,
    uuid: uuidv4(),
    vendorId: output?.vendorId ?? null,
  }));

export const mapAssemblyInputItemsToForm = (inputItems: NonNullableAssemblyInputItems): InputItem[] =>
  inputItems.map((input) => ({
    assemblyInputItemId: input?.assemblyInputItemId ?? null,
    assemblyInputPackages: mapAssemblyInputPackagesToForm(input?.assemblyInputPackages ?? []),
    inputItemTypeId: input?.inputItemTypeId,
    productId: input?.product?.id ?? null,
    productName: input?.product?.whseProductsDescription ?? '',
    productTypeId: input?.productType?.id ?? null,
    productTypeName: input?.productType?.productType ?? '',
    quantity: input?.quantity ?? 0,
    skip: !!input?.skip,
    unitAbbreviation: input?.unit?.abbreviation ?? '',
    unitId: input?.unit?.unitTypeId ?? null,
    uuid: uuidv4(),
  }));

export const mapAssemblyInputPackagesToForm = (inputPackages: NonNullableAssemblyInputPackages): InputPackage[] =>
  inputPackages.map((inputPackage) => ({
    assemblyInputPackageId: inputPackage?.assemblyInputPackageId ?? null,
    available:
      !!inputPackage?.package?.quantity && !!inputPackage?.package?.unit?.abbreviation
        ? buildAvailableQtyString(inputPackage.package.quantity, inputPackage.package.unit.abbreviation)
        : '',
    batchName: inputPackage?.package?.batch?.batchLotNumber ?? '',
    packageId: inputPackage?.package?.packageId ?? 0,
    quantity: inputPackage?.quantity ?? null,
    roomName: inputPackage?.package?.room?.roomNo ?? '',
    serialNumber: inputPackage?.package?.serialNumber ?? '',
    unitId: inputPackage?.package?.unit?.unitTypeId ?? null,
    useByDate: inputPackage?.package?.useByDate ?? '',
    uuid: uuidv4(),
  }));
