import { ServerPaginatedTables } from 'src/app/constants/table-names-paginated';
import { useServerTableControls } from 'src/app/hooks/use-server-table-controls';
import { UrlParams } from 'src/app/pages/manufacturing/assemblies/constants';
import { Direction, FilterOperator, LogicalOperator } from 'src/app/queries/types/gql-filtering-and-pagination';

import { filterArrayForValidNumbers } from '../../utils';

import type { AssemblyFilterInput, AssemblySortInput } from 'src/gql/graphql';

export const useAssembliesServerTableControls = () =>
  useServerTableControls<AssemblyFilterInput, AssemblySortInput>({
    initialSort: [{ key: 'name', direction: Direction.ASC }],
    filterDefinitions: [
      {
        urlParam: UrlParams.SEARCH,
        fields: ['name', 'billOfMaterials.name'],
        logicalOperator: LogicalOperator.OR,
        operator: FilterOperator.CONTAINS,
        initialValue: '',
      },
      {
        urlParam: UrlParams.STATUS,
        fields: ['assemblyStatusId'],
        logicalOperator: LogicalOperator.OR,
        operator: FilterOperator.IN,
        initialValue: [],
        customValueToApi(statuses) {
          return filterArrayForValidNumbers(statuses);
        },
      },
    ],
    tableName: ServerPaginatedTables.AssembliesList,
  });
