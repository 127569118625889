import { useQuery } from '@tanstack/react-query';

import { fetchData } from 'src/app/queries';

import { metrcKeys } from './query-key-factory';

export type ProcessingJobTypeCategory = {
  ForProcessingJobs: boolean;
  Id: number;
  Name: string;
  RequiresProcessingJobAttributes: boolean;
};

export type ProcessingJobTypeAttributes = {
  Id: number;
  LastModified: Date;
  Name: string;
};

export type ProcessingJobTypeResourcesResponse = {
  Attributes: ProcessingJobTypeAttributes[];
  Categories: ProcessingJobTypeCategory[];
};

export function useProcessingJobTypeResources() {
  return useQuery({
    queryKey: metrcKeys.processingJobTypes(),
    queryFn: () => fetchData<ProcessingJobTypeResourcesResponse>('api/metrc/get-metrc-job-types-resources'),
    staleTime: Infinity,
  });
}
