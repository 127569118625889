import React from 'react';

export function TimesIcon({ fill = 'var(--color-grayscale-black)' }: { fill?: string }): JSX.Element {
  return (
    <svg fill='none' height='12' viewBox='0 0 12 12' width='12' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.881565 11.0381C0.589334 10.7342 0.595916 10.2482 0.896265 9.95259L10.0454 0.947091C10.3457 0.651457 10.8261 0.658115 11.1184 0.961962C11.4106 1.26581 11.404 1.75178 11.1037 2.04742L1.95452 11.0529C1.65417 11.3486 1.17379 11.3419 0.881565 11.0381Z'
        fill={fill}
      />
      <path
        d='M11.0497 11.1085C10.7533 11.4083 10.2729 11.4083 9.97659 11.1085L0.950271 1.97707C0.653954 1.6773 0.653953 1.19128 0.950271 0.891513C1.24659 0.591745 1.72701 0.591745 2.02333 0.891514L11.0497 10.023C11.346 10.3227 11.346 10.8088 11.0497 11.1085Z'
        fill={fill}
      />
    </svg>
  );
}
