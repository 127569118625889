export enum PAGE_TITLE {
  Overview = 'Overview',
}

export enum BREAKDOWN_TYPE {
  AvgItemsPerCart = 'AvgItemsPerCart',
  AVO = 'AVO',
  ShoppingFrequency = 'ShoppingFrequency',
  TotalSpend = 'TotalSpend',
  TransactionCount = 'TransactionCount',
}

export const dateFormat = 'dd-mm-yyyy';
