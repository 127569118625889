import React, { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { Heading } from 'src/app/components/lib/heading';
import { PageVariant } from 'src/app/components/lib/page';
import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';
import TabPage from 'src/app/layout/main/tab-page';
import { SegmentReport } from 'src/app/pages/customers/customers/segments/details/reports/segment-reports';
import { SegmentAudience } from 'src/app/pages/customers/customers/segments/details/segment-audience';
import { Badge } from 'src/app/pages/products/audits-v2/detail/shared/badge';
import { useGetSegmentsQuery } from 'src/app/queries/segment/get-segments';
import { routes } from 'src/app/routes';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import { SegmentQueryBuilder } from './details/segment-query-builder';

import type { TabLink } from 'src/app/layout/main/tab-page';
import type { Segment } from 'src/app/queries/segment/get-segments';

const defaultEmptySegment: Segment = {
  AudienceSize: 0,
  CreatedBy: '',
  CreatedOnUtc: new Date().toLocaleDateString(),
  Definition: '',
  LastEditedUtc: new Date().toLocaleDateString(),
  Name: '',
  SegmentId: 0,
  IsDeleted: false,
  IsUpdating: false,
};

export function SegmentDetail() {
  const params = useParams();
  const { data: segments = [] } = useGetSegmentsQuery();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isChunkFlagEnabled = useLDFlag(LD.CUSTOMERS_PROJECT_CHUNK_ROLLOUT, false);
  const isReportsEnabled = useLDFlag(LD.CUSTOMERS_SEGMENTS_REPORTS_ROLLOUT, false);
  const canViewSegments = usePermissionCheck([Permissions.ViewSegments]);
  const canViewSegmentTab = canViewSegments && isChunkFlagEnabled;

  const [currentSegment, setCurrentSegment] = useState<Segment>({
    ...defaultEmptySegment,
  });

  useEffect(() => {
    if (params && segments?.length) {
      const segmentData = segments?.find((segment) => segment?.SegmentId?.toString() === params.segmentId) ?? {
        ...defaultEmptySegment,
      };
      setCurrentSegment(segmentData);
    }
  }, [params, segments]);

  const tabs: TabLink[] = [
    {
      label: 'Overview',
      to: 'overview',
      hasPermission: canViewSegmentTab,
      element: <SegmentReport />,
      hasNestedRoutes: true,
      hide: !isReportsEnabled,
    },
    {
      label: 'Audience',
      to: 'audience',
      badge: <StyledBadge>{currentSegment?.AudienceSize.toLocaleString()}</StyledBadge>,
      hasPermission: canViewSegmentTab,
      element: <SegmentAudience />,
      hasNestedRoutes: true,
    },
    {
      label: 'Segment details',
      to: 'edit-segment-details',
      hasPermission: canViewSegmentTab,
      element: <SegmentQueryBuilder />,
      hasNestedRoutes: false,
    },
  ];

  const navigateToListView = () => {
    navigate(routes.customers.segments.index);
    // remove the check-segment-status query from cache to remove stale already created segment polling information
    queryClient.removeQueries(['check-segment-status']);
  };

  const header = <Heading onBackAction={navigateToListView}>{currentSegment?.Name}</Heading>;

  return <TabPage header={header} tabs={tabs} variant={PageVariant.TableMaxWidth} />;
}

const StyledBadge = styled(Badge)`
  border-radius: 10px;
`;
