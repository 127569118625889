import type { QueryKey, QueryClient } from '@tanstack/react-query';
import type { AddNotificationArgs } from 'src/app/components/lib/notification/types';
import type { NotificationActions } from 'src/app/state/notifications';

type ClearStaleQueryCacheProps = {
  queryClient: QueryClient;
  queryKeys: QueryKey;
};

export const clearStaleQueryCache = ({ queryClient, queryKeys }: ClearStaleQueryCacheProps) => {
  const queryCache = queryClient.getQueryCache();
  const staleQueryKeys = queryCache
    .findAll(queryKeys)
    .filter((cache) => cache.getObserversCount() === 0)
    .map((cache) => cache.queryKey);
  // only clear stale queries if there are any, otherwise it will clear all queries
  if (staleQueryKeys.length) {
    queryClient.removeQueries({ queryKey: staleQueryKeys });
  }
};

export class ReactQueryError extends Error {
  action: keyof NotificationActions;
  args: AddNotificationArgs;

  constructor({ action = 'error', args }: { action?: keyof NotificationActions; args: AddNotificationArgs }) {
    super(args?.message);
    this.name = 'ReactQueryError';
    this.action = action;
    this.args = args;

    // Set the prototype explicitly to ensure instanceof works correctly
    Object.setPrototypeOf(this, ReactQueryError.prototype);
  }
}

export class RevokedSessionError extends Error {
  constructor() {
    super();
    this.name = 'RevokedSessionError';
    // Set the prototype explicitly to ensure instanceof works correctly
    Object.setPrototypeOf(this, RevokedSessionError.prototype);
  }
}
