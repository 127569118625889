import { useCallback, useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import { ValidationError } from 'yup';

import type { AnyObjectSchema } from 'yup';

type UseYupFormValidationProps<TFormData, TSchema extends AnyObjectSchema> = {
  context?: Record<string, any>;
  formData: TFormData;
  onValidation?: (errors: Record<string, string>) => void;
  schema: TSchema;
};
/**
 * Custom React hook for validating form data using a Yup schema.
 *
 * This hook automatically validates the provided `formData` against the given Yup `schema`.
 * It returns validation errors and a boolean indicating whether the form is valid.
 *
 * @template TFormData - The type of the form data to be validated.
 * @template TSchema - The Yup schema type to validate the form data against.
 *
 * @param {Object} props - The properties object.
 * @param {TFormData} props.formData - The current form data to be validated.
 * @param {TSchema} props.schema - The Yup schema used for validation.
 * @param {Record<string, any>} [props.context] - Optional context object to be passed to the validation schema.
 * @param {(errors: Record<string, string>) => void} [props.onValidation] - Optional callback function that gets invoked when validation occurs, providing a map of field errors.
 *
 * @returns {{
 *   errors: Record<string, string>;  // A map of form field names to their error messages, if any.
 *   isValid: boolean;               // A boolean indicating whether the form is valid (i.e., no validation errors).
 * }}
 *
 * @example
 * const { errors, isValid } = useYupFormValidation({
 *   formData: { name: 'Test', email: 'invalid-email' },
 *   schema: validationSchema,
 *   onValidation: (errors) => console.log('Validation errors:', errors),
 * });
 *
 * // errors: { email: 'Email must be a valid email address' }
 * // isValid: false
 */
export function useYupFormValidation<TFormData, TSchema extends AnyObjectSchema>({
  formData,
  schema,
  onValidation,
  context, // do not default this, it will create an referentially unstable object and cause an infinite loop in the useEffect
}: UseYupFormValidationProps<TFormData, TSchema>) {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const isValid = isEmpty(errors);
  const handleValidation = useCallback(() => {
    try {
      schema.validateSync(formData, { abortEarly: false, context });
      setErrors({});
      onValidation?.({});
    } catch (error) {
      if (error instanceof ValidationError) {
        const fieldErrors: Record<string, string> = {};
        error.inner.forEach((validationError) => {
          const path = validationError.path || ''; // Path is the fieldName
          fieldErrors[path] = validationError.message;
        });
        setErrors(fieldErrors);
        onValidation?.(fieldErrors);
      }
    }
  }, [schema, formData, context, onValidation]);

  useEffect(() => {
    handleValidation();
  }, [formData, handleValidation]);

  return {
    errors,
    isValid,
  };
}
