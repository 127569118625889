import { useLocation } from 'react-router-dom';

export default function useParentPath(): string {
  const { pathname } = useLocation();
  const paramsArray = pathname.split('/');
  paramsArray?.pop();
  const parentPath = paramsArray.join('/');

  return parentPath;
}
