import React from 'react';

import { getIDFromLabel } from 'src/app/components/lib/utils';
import { omit } from 'src/app/utils/omit';

import { ToggleChecked } from '../../icons/toggle-checked';
import { FormControl } from '../form-control';
import { Label } from '../label';

import { StyledToggleContainer, StyledToggle, StyledHelpText } from './toggle.styles';

export function Toggle(props) {
  const {
    label,
    helperText,
    checked,
    hideToggleState = false,
    enabledText = 'Enabled',
    disabledText = 'Disabled',
    required,
    toggleGridColumn = '2 / span 3',
    tooltip,
    gridColumns,
    automationId = '',
    onClick,
    id = '',
    disabled = false,
    fullWidth = undefined,
    ...rest
  } = props;

  const idFromLabel = getIDFromLabel(label);

  return (
    <FormControl $labelPlacement='toggle' className='toggle-form-control' gridColumns={gridColumns}>
      {label && (
        <Label
          htmlFor={`toggle-toggle_${String(id) ?? idFromLabel}`}
          id={`toggle-label_${String(id) ?? idFromLabel}`}
          required={required}
          tooltip={tooltip}
        >
          {label}
        </Label>
      )}
      <StyledToggleContainer
        $fullWidth={fullWidth === undefined ? !label : !!fullWidth}
        $toggleGridColumn={toggleGridColumn}
        className='toggle-toggle'
      >
        <StyledToggle
          {...omit(rest, ['labelPlacement'])}
          checked={checked}
          checkedIcon={<ToggleChecked />}
          data-testid={automationId}
          disabled={disabled}
          id={`toggle-toggle_${String(id) ?? idFromLabel}`}
          size='medium'
          onClick={onClick}
        />
        {!hideToggleState && <span>{checked ? enabledText : disabledText}</span>}
        {helperText && <StyledHelpText>{helperText}</StyledHelpText>}
      </StyledToggleContainer>
    </FormControl>
  );
}
