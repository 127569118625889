import { createContext, useContext, useReducer } from 'react';

import { noop } from 'lodash';

import { useBOMPermissions } from 'src/app/hooks/use-bom-permissions';
import {
  initializeDefaultAssemblyState,
  initializeEditAssemblyState,
} from 'src/app/pages/manufacturing/assemblies/utils';

import type { DocumentSchema, ProcessingJobTypeTemplateSchema } from '../../shared/schema';
import type { OutputItem } from '../types';
import type { AssemblyDetailResponse } from 'src/app/queries/graphql/assemblies/get-one';

export type SchemaContext = {
  useMetrcV2?: boolean;
};

export type AssemblyFormState = {
  billOfMaterialsId: number | null;
  documents: DocumentSchema[];
  estimatedStartDate: Date | null;
  name: string;
  outputs: OutputItem[];
  processingJobTypeTemplate: ProcessingJobTypeTemplateSchema;
};

export type AssemblyState = {
  context: SchemaContext;
  errors: Record<string, string>;
  formData: AssemblyFormState;
  initialFormData: AssemblyFormState;
  isDirty: boolean;
  isReadonly: boolean;
};

export type InitializeEditStateAction = {
  payload: AssemblyDetailResponse;
  type: 'initialize-edit-assembly-state';
};

export type SetErrorsAction = {
  payload: Record<string, string>;
  type: 'set-errors';
};

export type SetContextAction = {
  payload: SchemaContext;
  type: 'set-context';
};

export type ResetFormAction = {
  payload: undefined;
  type: 'reset-form';
};

export type SetIsDirtyAction = {
  payload: boolean;
  type: 'set-is-dirty';
};

export type Action =
  | InitializeEditStateAction
  | ResetFormAction
  | SetContextAction
  | SetErrorsAction
  | SetIsDirtyAction;

export function reducer(state: AssemblyState, action: Action): AssemblyState {
  const { payload, type } = action;
  switch (type) {
    case 'initialize-edit-assembly-state': {
      const initialData = initializeEditAssemblyState(payload);
      return {
        ...state,
        formData: initialData,
        initialFormData: initialData,
        errors: {},
        isDirty: false,
      };
    }
    case 'set-errors':
      return {
        ...state,
        errors: { ...payload },
      };

    case 'set-is-dirty':
      return {
        ...state,
        isDirty: payload,
      };

    case 'set-context':
      return {
        ...state,
        context: { ...payload },
      };
    case 'reset-form':
      return {
        ...state,
        formData: state.initialFormData,
        errors: {},
        isDirty: false,
      };
    default:
      return state;
  }
}

export function useAssemblyFormState() {
  const { canEditAssemblies } = useBOMPermissions();
  const initialFormData = initializeDefaultAssemblyState();
  return useReducer(reducer, {
    formData: initialFormData,
    initialFormData,
    errors: {},
    context: {},
    isDirty: false,
    isReadonly: !canEditAssemblies,
  });
}

export const AssemblyFormStateContext = createContext<ReturnType<typeof useAssemblyFormState>>([
  {
    formData: initializeDefaultAssemblyState(),
    initialFormData: initializeDefaultAssemblyState(),
    errors: {},
    context: {},
    isDirty: false,
    isReadonly: false,
  },
  noop,
]);

export function useAssemblyFormStateContext() {
  return useContext(AssemblyFormStateContext);
}
