import React from 'react';

import { useBlocker } from 'react-router-dom';

import ConfirmModal from './confirm-modal';

type BlockNavigationModalProps = {
  condition: boolean;
  helpText?: string;
};

/**
 * BlockNavigationModal
 *
 * WARNING: This has a dependency on LD.CREATE_BROWSER_ROUTER_ROLLOUT being enabled. WAIT TO USE UNTIL ROLLED OUT TO 100%
 * This component displays a confirmation modal when the user attempts to navigate away from the current page while changes are unsaved.
 * It uses the `useBlocker` hook to block navigation based on a provided condition.
 *
 * @param {boolean} condition - A condition that, when `true`, triggers the navigation blocking. If `false`, the navigation is not blocked.
 * @param {string} [helpText] - Optional text to display in the confirmation modal. If not provided, a default message is shown.
 *
 * @returns {JSX.Element} A modal that blocks navigation based on the specified condition.
 *
 * Example:
 * ```tsx
 * <BlockNavigationModal condition={hasUnsavedChanges} helpText="Custom message here" />
 * ```
 *
 * Props:
 * @typedef {Object} BlockNavigationModalProps
 * @property {boolean} condition - The condition used to determine if navigation should be blocked.
 * @property {string} [helpText] - Optional help text to display in the modal.
 *
 * @component
 */
export function BlockNavigationModal({ condition, helpText }: BlockNavigationModalProps) {
  const DEFAULT_HELP_TEXT = helpText || 'Are you sure you want to leave this page? Your changes will not be saved.';
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => condition && currentLocation.pathname !== nextLocation.pathname
  );
  return (
    <ConfirmModal
      helpText={DEFAULT_HELP_TEXT}
      justifyFooterContent='flex-end'
      open={blocker?.state === 'blocked'}
      onClose={() => blocker?.reset?.()}
      onConfirm={() => blocker?.proceed?.()}
    />
  );
}
