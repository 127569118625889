import React from 'react';

import { noop } from 'lodash';
import styled from 'styled-components';

import { Button } from 'src/app/components/lib/button';
import { ControlBar, ControlGroup, ControlSearchBox } from 'src/app/components/lib/control-bar';
import { DataGrid, type GridColDef } from 'src/app/components/lib/table';
import { BillOfMaterialDocuments } from 'src/app/constants/table-names';
import { EMPTY_DOCUMENTS_BODY, EMPTY_DOCUMENTS_HEADER } from 'src/app/pages/manufacturing/shared/constants';
import { EmptyMessage } from 'src/app/pages/manufacturing/shared/layout';

import { useDocuments } from '../hooks/use-documents';

import type { DocumentSchema } from '../schema';

export type DocumentsProps = {
  documents: DocumentSchema[];
  isReadonly: boolean;
  openAddDocumentModal?: () => void;
  openEditDocumentModal?: (document: DocumentSchema) => void;
  removeDocument?: (index: number) => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

export function Documents({
  documents,
  isReadonly,
  openAddDocumentModal = noop,
  openEditDocumentModal,
  removeDocument,
  search,
  setSearch,
}: DocumentsProps) {
  const { columns, filteredDocuments } = useDocuments({
    documents,
    isReadonly,
    openEditDocumentModal,
    removeDocument,
    search,
  });
  return (
    <DocumentsContainer>
      <ControlBar>
        <ControlGroup gap='12px' grow>
          <ControlSearchBox initialValue={search} placeholder='Search documents by name...' onChange={setSearch} />
          {!isReadonly && <Button label='Add' onClick={openAddDocumentModal} />}
        </ControlGroup>
      </ControlBar>
      {documents.length > 0 ? (
        <DataGrid
          columns={columns as GridColDef[]}
          disableSelectionOnClick
          getRowId={(row) => row.uuid}
          height='55vh'
          name={BillOfMaterialDocuments}
          rows={filteredDocuments}
        />
      ) : (
        <EmptyMessage body={EMPTY_DOCUMENTS_BODY} header={EMPTY_DOCUMENTS_HEADER} />
      )}
    </DocumentsContainer>
  );
}

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
