import React, { useMemo } from 'react';

import i18next from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';

import BlockSpinner from 'src/app/components/block/block-spinner';
import { HeaderContainer } from 'src/app/components/lib/header-container';
import { Heading } from 'src/app/components/lib/heading';
import { Page, PageVariant } from 'src/app/components/lib/page';
import { ItemNotFound } from 'src/app/layout/error/item-not-found';
import { useAssemblyQuery } from 'src/app/queries/graphql/assemblies/get-one';
import { routes } from 'src/app/routes';

import { AssemblyDetailForm } from '..';
import { AssemblyFormStateContext, useAssemblyFormState } from '../use-form-state';

export function EditAssembly() {
  const { assemblyId } = useParams();
  const values = useAssemblyFormState();
  const enableFetch = useMemo(() => assemblyId !== undefined && !Number.isNaN(Number(assemblyId)), [assemblyId]);
  const navigate = useNavigate();
  const navigateBackToListPage = () => navigate(routes.manufacturing.assemblies);

  const {
    data: assemblyData,
    isLoading,
    isError,
  } = useAssemblyQuery({
    assemblyId: Number(assemblyId),
    enabled: enableFetch,
    onError: navigateBackToListPage,
  });

  const hasError = isError || Number.isNaN(assemblyId);

  const { name = '' } = assemblyData ?? {};

  return (
    <>
      {hasError && <ItemNotFound item='Assembly' listPageName='assemblies' />}
      {!hasError && (
        <Page variant={PageVariant.Table}>
          <BlockSpinner visible={isLoading} />
          {!isLoading && !!assemblyData && (
            <>
              <HeaderContainer>
                <Heading backBtnTestId='edit-assembly-back-to-list' onBackAction={navigateBackToListPage}>
                  {i18next.t(name)}
                </Heading>
              </HeaderContainer>
              <AssemblyFormStateContext.Provider value={values}>
                <AssemblyDetailForm assemblyData={assemblyData} formType='edit' />
              </AssemblyFormStateContext.Provider>
            </>
          )}
        </Page>
      )}
    </>
  );
}
