import { isEqual } from 'lodash';

import { base64ToBlobUrl, downloadFile, isBase64 } from 'src/app/utils/file-processing';

import { DocumentType } from './constants';

export const computeIsDirty = <T>(formData: T, initialFormData: T) => !isEqual(formData, initialFormData);

export const openOrDownload = (documentTypeId: DocumentType, fileUrl: string, url: string, fileName: string) => {
  if (documentTypeId === DocumentType.Link) {
    window.open(url ?? '', '_blank');
  } else {
    const linkToOpen = isBase64(fileUrl) ? base64ToBlobUrl(fileUrl) : fileUrl ?? '';
    if (linkToOpen) {
      downloadFile(linkToOpen, fileName);
    }
  }
};
