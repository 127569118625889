const breakdownColors = [
  '--color-yellow-50',
  '--color-brand-secondary-sapphire',
  '--color-green-20',
  '--color-red-30',
  '--color-teal-80',
  '--color-orange-100',
  '--color-yellow-10',
  '--color-opal-80',
  '--color-red-80',
  '--color-green-80',
  '--color-blue-80',
  '--color-brand-secondary-lavender',
  '--color-teal-10',
  '--color-brand-secondary-moss',
  '--color-brand-secondary-terra',
  '--color-brand-secondary-cedar',
  '--color-brand-primary-flora',
  '--color-blue-30',
  '--color-opal-40',
  '--color-red-70',
  '--color-teal-70',
  '--color-purple-50',
  '--color-orange-50',
  '--color-opal-10',
  '--color-teal-30',
  '--color-yellow-100',
  '--color-purple-10',
  '--color-brand-primary-dutchie-pay',
  '--color-orange-10',
  '--color-purple-100',
];

/**
 * Get's a color based on the index. If all predefined colors are exhausted a random one will be generated. All colors will be unique.
 * @param index the index of the item to color
 * @returns a hex color string
 */
export const getColor = (index: number): string => {
  if (index < breakdownColors.length) {
    return getCssVarValueFromName(breakdownColors[index]);
  }

  while (true) {
    const randomColor = randomHexColorCode();
    const colorAlreadyExists = breakdownColors.find((color) => getCssVarValueFromName(color) === randomColor);
    if (!colorAlreadyExists) {
      return randomColor;
    }
  }
};

const randomHexColorCode = () => {
  const n = Math.floor(Math.random() * 0xffffff).toString(16);
  return `#${n.slice(0, 6)}`;
};

// github.com/chartjs/Chart.js/issues/9983
export function getCssVarValueFromName(name: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(name);
}

export const colorArr = [
  '--color-teal-100',
  '--color-teal-90',
  '--color-teal-80',
  '--color-teal-70',
  '--color-teal-60',
  '--color-teal-50',
  '--color-teal-40',
  '--color-teal-30',
  '--color-teal-20',
  '--color-teal-10',
];
