import React from 'react';

import { format } from 'date-fns';

import { BlueProgressBadge, GreenFilledCheckBadge, RedErrorBadge } from 'src/app/components/lib/badge-v2';
import { getLinkCellColumn } from 'src/app/components/lib/table';

import { Status } from '../../constants';

import type { GridColDef } from 'src/app/components/lib/table';
import type { GridColumn } from 'src/app/queries/types/gql-filtering-and-pagination';
import type { AssemblyAttributesFragment, AssemblySortInput } from 'src/gql/graphql';

type AssemblyGridColumn = GridColumn<AssemblySortInput, AssemblyAttributesFragment>;

export function useAssemblyColumns(): GridColDef[] {
  const columns: AssemblyGridColumn[] = [
    getLinkCellColumn({
      column: {
        field: 'name',
        headerName: 'Name',
        headerAlign: 'left',
        align: 'left',
        width: 250,
      },
      options: { shouldRenderLink: true },
    }),
    {
      field: 'billOfMaterials.name',
      headerName: 'BOM Used',
      width: 250,
      valueGetter: ({ row }) => row.billOfMaterials?.name ?? '',
    },
    {
      field: 'startedUtc',
      headerName: 'Started',
      width: 250,
      valueGetter: ({ value }) => value && format(new Date(value), 'MM/dd/yyyy'),
    },
    {
      field: 'assemblyStatus.name',
      headerName: 'Status',
      minWidth: 150,
      valueGetter: ({ row }) => row.assemblyStatus?.name ?? '',
      renderCell: ({ row }) => {
        const statusId = row.assemblyStatus?.assemblyStatusId ?? Status.INCOMPLETE;
        switch (statusId) {
          case Status.INCOMPLETE:
            return <RedErrorBadge label='Incomplete' />;
          case Status.IN_PROGRESS:
            return <BlueProgressBadge label='In Progress' />;
          case Status.COMPLETE:
            return <GreenFilledCheckBadge label='Complete' />;
          default:
            return <RedErrorBadge label='Unknown' />;
        }
      },
    },
    {
      field: 'completedUtc',
      headerName: 'Completed',
      width: 250,
      valueGetter: ({ value }) => value && format(new Date(value), 'MM/dd/yyyy'),
    },
  ];
  return columns;
}
