import React from 'react';

import { Link } from 'react-router-dom';

import { Card, Content, Frame, Header } from 'src/app/layout/error/styled-components';

import { getArticle } from './helpers';
import useParentPath from './hooks/use-parent-path';
import { ItemNotFoundIcon } from './icons/item-not-found-icon';

type ItemNotFoundProps = {
  item: string;
  listPageName: string;
};

export function ItemNotFound({ item, listPageName }: ItemNotFoundProps) {
  const listPagePath = useParentPath();
  return (
    <Frame>
      <Card>
        <ItemNotFoundIcon />
        <Header>{`We can't find the ${item} you are looking for.`}</Header>
        <Content>
          {`It looks like you've stumbled upon ${getArticle(item)} ${item} that doesn't exist.`}
          <span>
            {`To see a list of ${item}s, visit the `}
            <Link to={listPagePath}>{`${listPageName} page`}</Link>.
          </span>
        </Content>
      </Card>
    </Frame>
  );
}
