import React, { Suspense, useEffect, useState } from 'react';

import axios from 'axios';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  BrowserRouter as RouterV6,
} from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Main from 'src/app/layout/main/router';

import App from './app/app';
import { LD } from './app/constants/launch-darkly-flags';
import { IdentifiedFlagsContext } from './app/hooks/use-identified-ld-flags';
import { useLDFlag } from './app/hooks/use-ld-flag';
import { useLogoutOnSessionRevocation } from './app/hooks/use-logout-on-session-revocation';
import { useTrackBrowserDimensions } from './app/hooks/use-track-browser-dimensions';
import { useTrackErrorNotifications } from './app/hooks/use-track-error-notifications';
import { useRegionQuery } from './app/queries/admin/get-region';
import { analyticsAtom } from './app/state/analytics';
import { environmentAtom } from './app/state/environment';
import { userAtom } from './app/state/user';
import { userDispensariesAtom } from './app/state/user-dispensaries';
import { setLDClient } from './app/utils/ld-client-do-not-use-me';
import { getRequestHeaders } from './app/utils/request-headers';
import AppActions from './app_deprecated/actions/AppActions';

import type { Analytics } from './app/state/analytics';
import type { EnvironmentDetails } from './app/state/environment';
import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route element={<Main />} path='/*' />
    </Route>
  )
);

export function Root({ environment }: { environment: EnvironmentDetails | undefined }) {
  const ldClient = useLDClient();
  const createBrowserRouterEnabled = useLDFlag(LD.CREATE_BROWSER_ROUTER_ROLLOUT, false);
  const [identifiedFlags, setIdentifiedFlags] = useState<LDFlagSet | null>(null);
  const [_analytics, setAnalytics] = useRecoilState(analyticsAtom);
  const userDispensaries = useRecoilValue(userDispensariesAtom);
  const user = useRecoilValue(userAtom);
  const setEnvironmentDetails = useSetRecoilState(environmentAtom);
  const { selectedLocation, selectedCompany } = userDispensaries;
  const { data: regionResponse } = useRegionQuery();
  const region = regionResponse?.Data;

  useEffect(() => {
    // some of the old stores rely on this value being available
    AppActions.getRegion();
  }, []);

  useEffect(() => {
    setLDClient(ldClient);
  }, [ldClient]);

  useTrackBrowserDimensions();
  useTrackErrorNotifications();

  useEffect(() => {
    if (environment) {
      setEnvironmentDetails(environment);
    }
  }, [environment, setEnvironmentDetails]);

  useEffect(() => {
    const analyticsWithDeferredPendoTrack: Analytics = {
      dangerousAllowMutability: true,
      key: 'analytics',
      track: (eventName, eventData, retryCount = 0) => {
        try {
          const appVersionEl = document.querySelector('meta[name="version"]') as HTMLMetaElement;
          const appVersion = appVersionEl?.content ?? 'unknown';
          const baseEventData = {
            host: window.location.hostname,
            appVersion,
            userFullName: user?.FullName,
            username: user?.UserName,
            userID: user?.Id,
            locId: user?.LocId,
            lspId: user?.LspId,
          };

          if (window.pendo?.isReady?.()) {
            window.pendo.track(eventName, { ...baseEventData, ...eventData });
          } else if (retryCount < 3) {
            setTimeout(() => analyticsWithDeferredPendoTrack.track(eventName, eventData, retryCount + 1), 500);
          }
        } catch (error) {
          console.error('Error tracking event', error);
        }
      },
    };

    setAnalytics(analyticsWithDeferredPendoTrack);
  }, [setAnalytics, user]);

  useEffect(() => {
    const getAndSetFlags = async () => {
      if (ldClient && selectedCompany && selectedLocation && region && user) {
        const latestFlags = await ldClient.identify({
          key: user.UserName,
          kind: 'user',
          email: user.UserName,
          locId: selectedLocation.LocId,
          lspId: selectedCompany.LspId,
          region,
          host: window.location.hostname,
        });
        setIdentifiedFlags(latestFlags);
        axios.defaults.headers.common = {
          ...axios.defaults.headers.common,
          ...getRequestHeaders(),
        };
      }
    };
    void getAndSetFlags();
  }, [selectedCompany, selectedLocation, region, user, ldClient, setIdentifiedFlags]);

  const identifiedFlagsContextValue = React.useMemo(
    () => ({
      identifiedFlags,
    }),
    [identifiedFlags]
  );

  useLogoutOnSessionRevocation();

  return (
    <Suspense fallback={<div />}>
      <IdentifiedFlagsContext.Provider value={identifiedFlagsContextValue}>
        {createBrowserRouterEnabled ? (
          <RouterProvider router={router} />
        ) : (
          <RouterV6>
            <App />
          </RouterV6>
        )}
      </IdentifiedFlagsContext.Provider>
    </Suspense>
  );
}
