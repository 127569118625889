import { useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';
import uuid from 'uuid/v1';

import { LD } from 'src/app/constants/launch-darkly-flags';
import { useLDFlag } from 'src/app/hooks/use-ld-flag';

import { useNotificationActions } from '../../../../../../state/notifications';

import { isDirtyAtom } from './query-builder-atom';
import { validateAllRules } from './validation';

import type { CustomerSegmentSchemaMap } from './schema';

export type Rule = {
  Attribute: string | '';
  Condition: string | '';
  Id?: string;
  Operator: string;
  SecondValue?: string | null;
  Value: string;
  Variant?: string | null;
};

export type RuleGroup = {
  combinator: string;
  id?: string;
  rules: Rule[];
};

type QueryBuilderProps = {
  customerSegmentSchema?: CustomerSegmentSchemaMap;
  segmentDetails?: RuleGroup;
};

type UseQueryBuilder = {
  addRule: () => void;
  areQueryRulesValid?: boolean;
  isDirty: boolean;
  removeRule: (id: string) => void;
  ruleGroup: RuleGroup;
  updateCombinator: (combinator: string) => void;
  updateRule: (id: string, newValues: Partial<Rule>) => void;
};

/**
 * This hook manages a dynamic rule group for building complex queries, providing functionalities for adding, updating, and removing rules, as well as handling the combinator.
 * @param {RuleGroup} [props.segmentDetails] - An optional initial set of rules and combinator to start with.
 * @returns {Object} An object with methods and state for managing the rule group:
 *   - ruleGroup: The current state of the rule group.
 *   - addRule: Adds a new rule to the group, ensuring the rule limit (5) is not exceeded.
 *   - removeRule: Removes a rule from the group by its ID.
 *   - updateRule: Updates specific attributes of a rule by its ID.
 *   - updateCombinator: Changes the combinator used to combine rules within the group.
 *   - areQueryRulesValid: A boolean indicating is the query rules are valid or not.
 *   - isDirty: A boolean indicating whether the rule group has been modified.
 */

const useQueryBuilder = ({ segmentDetails, customerSegmentSchema }: QueryBuilderProps): UseQueryBuilder => {
  // start with a default
  const [ruleGroup, setRuleGroup] = useState<RuleGroup>({
    combinator: 'and',
    rules: [
      {
        Id: uuid(),
        Condition: '',
        Attribute: '',
        Operator: '',
        Value: '',
        SecondValue: '',
        Variant: '',
      },
    ],
  });
  const notifications = useNotificationActions();
  const enableMultiSelectDropdowns = useLDFlag<boolean>(LD.CUSTOMERS_MULTISELECTS_QUERY_BUILDER);
  const enableSegmentsV2 = useLDFlag<boolean>(LD.CUSTOMERS_SEGMENTS_V2);

  useEffect(() => {
    if (segmentDetails) {
      setRuleGroup(segmentDetails);
    }
  }, [segmentDetails]);

  // validate all rules on mount
  const areRulesValid = validateAllRules({
    ruleGroup: segmentDetails,
    customerSegmentSchema,
    enableMultiSelectDropdowns,
    enableSegmentsV2,
  });
  const [areQueryRulesValid, setAreQueryRulesValid] = useState(areRulesValid);
  const [isDirty, setIsDirty] = useRecoilState(isDirtyAtom);

  const updateCombinator = (newCombinator: string) => {
    setRuleGroup({
      ...ruleGroup,
      combinator: newCombinator,
    });
    setIsDirty(true);
  };

  const addRule = () => {
    if (ruleGroup.rules.length >= 5) {
      const errorMessage = 'Cannot add more than 5 rules.';
      notifications.alert({ message: errorMessage });
      return;
    }

    const newRule: Rule = {
      Id: uuid(),
      Condition: '',
      Attribute: '',
      Operator: '',
      Value: '',
      SecondValue: '',
      Variant: '',
    };
    const updatedRuleGroup = {
      ...ruleGroup,
      rules: [...ruleGroup.rules, newRule],
    };
    setRuleGroup(updatedRuleGroup);
    const areRulesValid = validateAllRules({
      ruleGroup: updatedRuleGroup,
      customerSegmentSchema,
      enableMultiSelectDropdowns,
      enableSegmentsV2,
    });
    setAreQueryRulesValid(areRulesValid);
  };

  const removeRule = (id: string) => {
    setRuleGroup({
      ...ruleGroup,
      rules: ruleGroup.rules.filter((rule) => rule.Id !== id),
    });
    // removing a rule makes the form dirty
    setIsDirty(true);
  };

  const updateRule = (id: string, newValues: Partial<Rule>) => {
    const updatedRuleGroup = {
      ...ruleGroup,
      rules: ruleGroup.rules.map((rule) => (rule.Id === id ? { ...rule, ...newValues } : rule)),
    };

    const updatedRule = updatedRuleGroup.rules.find((rule) => rule.Id === id);

    if (updatedRule) {
      const areRulesValid = validateAllRules({
        ruleGroup: updatedRuleGroup,
        customerSegmentSchema,
        enableMultiSelectDropdowns,
        enableSegmentsV2,
      });

      setRuleGroup(updatedRuleGroup);
      setIsDirty(true);
      setAreQueryRulesValid(areRulesValid);
    }
  };

  return {
    ruleGroup,
    updateCombinator,
    addRule,
    removeRule,
    updateRule,
    areQueryRulesValid,
    isDirty,
  };
};

export default useQueryBuilder;
