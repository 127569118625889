// Comment to make v8 happy. See https://github.com/istanbuljs/v8-to-istanbul/issues/198#issuecomment-1464375388

export const TABS = {
  details: { label: 'Details', queryParam: 'details' },
  documents: { label: 'Documents', queryParam: 'documents' },
  processingJobs: { label: 'Processing jobs', queryParam: 'processing-jobs' },
};
export enum DocumentType {
  File = 1,
  Link = 2,
}

export const NAME_REQUIRED_MESSAGE = 'Name is required';
export const DOCUMENT_NAME_REQUIRED_MESSAGE = 'Document Name is required';
export const FILE_NAME_REQUIRED_MESSAGE = 'File Name is required when Document is a File';
export const URL_REQUIRED_MESSAGE = 'URL is required';
export const INVALID_URL_FORMAT = 'Invalid URL format';
export const ATTRIBUTE_REQUIRED_MESSAGE = 'At least one attribute is required for processing jobs';
export const CATEGORY_NAME_REQUIRED_MESSAGE = 'Category Name is required for processing jobs';
export const DESCRIPTION_REQUIRED_MESSAGE = 'Description is required for processing jobs';
export const DESCRIPTION_MAX_LENGTH_MESSAGE = 'Description cannot exceed 1000 characters';
export const OUTPUT_MIN_MESSAGE = 'No outputs defined';
export const INPUT_MIN_MESSAGE = 'No inputs defined';
export const QUANTITY_REQUIRED_MESSAGE = 'Quantity is required';
export const EMPTY_ATTRIBUTES_HEADER = 'No attributes added';
export const EMPTY_ATTRIBUTES_BODY = 'Add attributes to complete the processing job.';
export const EMPTY_DOCUMENTS_HEADER = 'No documents added yet';
export const EMPTY_DOCUMENTS_BODY =
  'You may add supporting documents for this BOM as a link to a file or a direct download.';
