import { useQuery } from '@tanstack/react-query';

import { fetchMany } from 'src/app/queries/graphql';
import { assemblyKeys } from 'src/app/queries/graphql/assemblies/query-key-factory';
import { GetAssembliesDocument } from 'src/gql/graphql';

import type { AbridgedFilterInput, QueryProps } from 'src/app/queries/types/gql-filtering-and-pagination';
import type { AssemblyFilterInput, AssemblySortInput, AssemblyAttributesFragment } from 'src/gql/graphql';

type UseAssemblyQueryProps = QueryProps<AbridgedFilterInput<AssemblyFilterInput>, AssemblySortInput>;

export function useAssembliesQuery(queryProps: UseAssemblyQueryProps) {
  return useQuery({
    queryKey: assemblyKeys.many(queryProps),
    queryFn: async () => {
      const assembliesData = await fetchMany(GetAssembliesDocument, queryProps);
      const items = assembliesData?.items?.filter(Boolean) as AssemblyAttributesFragment[];
      return {
        ...assembliesData,
        items,
      };
    },
    keepPreviousData: true,
  });
}
