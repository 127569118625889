import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'universal-cookie';

import UserStore from 'src/app_deprecated/stores/UserStore';

import { LD } from '../constants/launch-darkly-flags';
import { useNotificationActions } from '../state/notifications';

import { useLDFlag } from './use-ld-flag';

export function useLogoutOnSessionRevocation() {
  const queryClient = useQueryClient();
  const flagEnabled = useLDFlag(LD.LOG_OUT_ON_SESSION_REVOCATION);
  const notifications = useNotificationActions();

  useEffect(() => {
    let responseErrorInterceptor: number | undefined;
    if (flagEnabled) {
      responseErrorInterceptor = axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error?.response?.status === 401) {
            const cookies = new Cookies();
            const session = cookies.get('LeafLogixSessionInfo');
            if (session !== undefined) {
              UserStore.logout();
              queryClient.clear();
              notifications.error({ message: 'Your session has expired. Please login again to proceed.' });
            }
          }
          return Promise.reject(error);
        }
      );
    }

    if (!flagEnabled && responseErrorInterceptor !== undefined) {
      axios.interceptors.request.eject(responseErrorInterceptor);
    }
  }, [flagEnabled, notifications, queryClient]);
}
