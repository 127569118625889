import React from 'react';

import { Button } from 'src/app/components/lib/button';
import { FormStickyFooter } from 'src/app/components/lib/form';
import { Tab } from 'src/app/components/lib/tab';
import { TabPanel } from 'src/app/components/lib/tab-panel';
import { Tabs } from 'src/app/components/lib/tabs';
import { BlockNavigationModal } from 'src/app/components/modals/modals/block-navigation-modal';
import { FullWidthContainer } from 'src/app/pages/manufacturing/shared/layout';

import { useAssemblyForm } from './use-assembly-form';

import type { AssemblyDetailResponse } from 'src/app/queries/graphql/assemblies/get-one';

export type AssemblyDetailFormProps = {
  assemblyData?: AssemblyDetailResponse;
  formType: 'create' | 'edit';
};

export function AssemblyDetailForm({ formType, assemblyData }: AssemblyDetailFormProps) {
  const { activeTab, setActiveTab, onSubmit, showBlockNavigationModal, tabs, isValid, isLoading, isDirty, isReadonly } =
    useAssemblyForm({
      formType,
      assemblyData,
    });
  return (
    <>
      {/* Navigation Tabs */}
      <Tabs value={activeTab} onChange={(_, newTab) => setActiveTab(newTab)}>
        {tabs.map(({ queryParam, label }) => (
          <Tab key={queryParam} label={label} />
        ))}
      </Tabs>
      {/* Form Content */}
      <FullWidthContainer>
        {/* Assembly Tab Content */}
        {tabs.map(({ queryParam, component: Component }, index) => (
          <TabPanel index={index} key={`${queryParam}`} value={activeTab}>
            <Component />
          </TabPanel>
        ))}
        <FormStickyFooter
          Actions={
            <Button
              data-testid={`assembly-form-submit_${formType}`}
              disabled={!isValid || isLoading || !isDirty || isReadonly}
              label='Save'
              loading={isLoading}
              type='submit'
              onClick={onSubmit}
            />
          }
        />
      </FullWidthContainer>
      {/* Block Navigation Modal */}
      {showBlockNavigationModal && <BlockNavigationModal condition={isDirty} />}
    </>
  );
}
