import React from 'react';

import styled from 'styled-components';

import { ErrorIcon } from '../../icons/error-icon';
import { InfoIcon } from '../../icons/info-icon';
import { SuccessIcon } from '../../icons/success-icon';
import { WarningIcon } from '../../icons/warning-icon';

export enum AlertBannerStyles {
  default = 'default',
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

type AlertBannerStyleProps = Record<
  string,
  {
    background: string;
    displayIcon?: JSX.Element;
    textColor: string;
  }
>;

const StyledErrorIcon = styled(ErrorIcon)`
  margin-top: 0 !important;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  margin-top: 0 !important;
`;

const StyledWarningIcon = styled(WarningIcon)`
  margin-top: 0 !important;
`;

const StyledInfoIcon = styled(InfoIcon)`
  margin-top: 0 !important;
`;

export const AlertBannerStyle: AlertBannerStyleProps = {
  default: {
    background: 'var(--color-gray-20)',
    textColor: 'var(--color-grayscale-black)',
  },
  error: {
    background: 'var(--color-red-10)',
    textColor: 'var(--color-red-60)',
    displayIcon: <StyledErrorIcon />,
  },
  info: {
    background: 'var(--color-blue-10)',
    textColor: 'var(--color-blue-80)',
    displayIcon: <StyledInfoIcon />,
  },
  success: {
    background: 'var(--color-green-10)',
    textColor: 'var(--color-green-80)',
    displayIcon: <StyledSuccessIcon />,
  },
  warning: {
    background: '#FFF6C7',
    textColor: '#615100',
    displayIcon: <StyledWarningIcon />,
  },
};
