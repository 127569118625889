import React from 'react';

import styled, { css } from 'styled-components';

import { Tooltip } from 'src/app/components/lib/tooltip';
import { ExtraSmallText, Heading3 } from 'src/app/components/lib/typography';

type ContentCardTileProps = {
  content: string;
  label: string;
  onClick: () => void;
  selected: boolean;
  tooltip: string;
};

export function ContentCardTile(props: ContentCardTileProps) {
  const { label, content, tooltip, onClick, selected } = props;
  return (
    <TileContainer selected={selected} onClick={onClick}>
      <Tooltip placement='top' title={tooltip}>
        <StyledLabel>{label}</StyledLabel>
      </Tooltip>
      <Heading3>{content}</Heading3>
    </TileContainer>
  );
}

const StyledLabel = styled(ExtraSmallText)`
  text-decoration: underline dashed;
`;

const TileContainer = styled.section<{ selected: boolean }>`
  padding: 16px 20px;
  border-radius: 12px;
  :hover {
    cursor: pointer;
  }

  ${(props) => {
    if (props.selected) {
      return css`
        * {
          color: var(--color-brand-primary-white);
        }
        background-color: var(--color-opal-90);
      `;
    }

    return css``;
  }}
`;
