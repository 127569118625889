import React from 'react';

export type TabPanelProps = {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
};

export const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>((props, ref) => {
  const { children, value, index, ...other } = props;
  const isSelected = value === index;

  return (
    <div
      aria-labelledby={`full-width-tab-${index}`}
      className='tab-panel'
      hidden={!isSelected}
      id={`full-width-tabpanel-${index}`}
      ref={ref}
      role='tabpanel'
      {...other}
    >
      {isSelected && children}
    </div>
  );
});
