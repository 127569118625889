import imageCompression from 'browser-image-compression';
import uuid from 'uuid';

import { fileSize } from 'src/app_deprecated/constants/AppConstants';

const mimeToExtensionMap = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'image/svg+xml': 'svg',
  'application/pdf': 'pdf',
};

export type ImageCompressionOptions = {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
};

export const fileToBase64 = async (file: File, compressionOptions?: ImageCompressionOptions) => {
  let image = file;

  if (compressionOptions) {
    try {
      const compressedImage = await imageCompression(file, compressionOptions);
      image = compressedImage;
    } catch (e) {
      console.log(e);
    }
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(image);
  });
};

export function getFileSizeFromBase64String(base64String?: string) {
  if (base64String) {
    const indexOfComma = base64String.indexOf(',');
    const prefix = base64String.slice(0, indexOfComma + 1);
    const stringLength = base64String.length - prefix.length;
    const sizeInBytes = stringLength * (3 / 4);

    return sizeInBytes;
  }
  return 0;
}

export const getExtensionFromBase64 = (base64String: string): string => {
  const mimeType = getBase64MimeTypeFromUrl(base64String);
  return mimeToExtensionMap[mimeType] || '';
};

export const buildNewFileName = (base64String: string): string => {
  const ext = getExtensionFromBase64(base64String);
  const fileName = `${uuid()}.${ext}`;
  return fileName;
};

export const getFileSize = (file: File): Promise<number> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(new Uint8Array(reader.result).length);
      } else {
        reject(new Error('File reading failed'));
      }
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });

export const getBase64MimeTypeFromUrl = (url: string): string => url.substring(url.indexOf(':') + 1, url.indexOf(';'));

export const getBase64ImageUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('File reading failed'));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const validateImageSize = (imageUrl: string) => {
  const imageFileSize = getFileSizeFromBase64String(imageUrl);
  if (imageFileSize >= fileSize.FOUR_MB) {
    throw new Error('Please upload an image smaller than 4 MB');
  }
};

export const base64ToBlobUrl = (base64: string) => {
  if (!isBase64(base64)) {
    throw new Error('Invalid base64 string');
  }
  const mimeType = getBase64MimeTypeFromUrl(base64);
  const byteString = atob(base64.split(',')[1]);
  const uint8Array = Uint8Array.from(byteString, (char) => char.charCodeAt(0));
  return URL.createObjectURL(new Blob([uint8Array], { type: mimeType }));
};

export const isBase64 = (str: string | null | undefined) => {
  if (!str) {
    return false;
  }
  const base64Regex = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,[A-Za-z0-9+/]+={0,2}$/;
  return base64Regex.test(str);
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
