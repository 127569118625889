import React from 'react';

import styled from 'styled-components';

import { Label } from 'src/app/components/lib/label';

export function EmptyMessage({ header, body }: { body: string; header: string }) {
  return (
    <EmptyContainer>
      <EmptyTextContainer>
        <EmptyHeader>{header}</EmptyHeader>
        <EmptyBody>{body}</EmptyBody>
      </EmptyTextContainer>
    </EmptyContainer>
  );
}

export const OutputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--sizes-50);
  align-self: stretch;
  margin-bottom: var(--sizes-80);
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--color-grayscale-black);
  text-align: center;
  font: var(--font-large-16pt-semibold);
  line-height: var(--sizes-60);
`;

export const EmptyContainer = styled.div`
  display: flex;
  height: 162px;
  padding: 56px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--sizes-20);
  align-self: stretch;
  border-radius: var(--sizes-30);
  background: var(--color-gray-10);
`;

export const EmptyTextContainer = styled.div`
  display: flex;
  width: 640px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--sizes-20);
`;

export const EmptyHeader = styled.div`
  color: var(--color-gray-80);
  text-align: center;
  font: var(--font-large-16pt-semibold);
  line-height: var(--sizes-60);
`;

export const EmptyBody = styled.div`
  color: var(--color-gray-80);
  text-align: center;
  font-feature-settings: 'calt' off;
  font: var(--font-regular-14pt-normal);
  line-height: var(--sizes-50);
`;

export const StyledLabel = styled(Label)`
  width: 100px;
  color: var(--color-grayscale-black);
  text-align: center;
  font-feature-settings: 'calt' off;
  font: var(--font-large-16pt-normal);
  line-height: var(--sizes-60);
`;

export const FullWidthContainer = styled.div`
  width: 100%;
  margin-bottom: var(--sizes-110);
`;
