import React from 'react';

import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';

import { HeaderContainer } from 'src/app/components/lib/header-container';
import { Heading } from 'src/app/components/lib/heading';
import { Page, PageVariant } from 'src/app/components/lib/page';
import { routes } from 'src/app/routes';

import { AssemblyDetailForm } from '..';
import { NEW_ASSEMBLY_PAGE_TITLE } from '../../constants';
import { AssemblyFormStateContext, useAssemblyFormState } from '../use-form-state';

export function CreateAssembly() {
  const values = useAssemblyFormState();
  const navigate = useNavigate();
  const navigateBackToListPage = () => navigate(routes.manufacturing.assemblies);
  return (
    <Page variant={PageVariant.Table}>
      <HeaderContainer>
        <Heading backBtnTestId='create-assemblies-back-to-list' onBackAction={navigateBackToListPage}>
          {i18next.t(NEW_ASSEMBLY_PAGE_TITLE)}
        </Heading>
      </HeaderContainer>
      <AssemblyFormStateContext.Provider value={values}>
        <AssemblyDetailForm formType='create' />
      </AssemblyFormStateContext.Provider>
    </Page>
  );
}
