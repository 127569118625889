import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { Permissions, userPermissionsAtom } from 'src/app/state/user-permissions';

import { userDispensariesAtom } from '../state/user-dispensaries';

export function useBOMPermissions() {
  const { integrations } = useRecoilValue(userDispensariesAtom);
  const { UseMetrc, UseBioTrack, UseMetrcV2 } = integrations;
  // To begin, this feature is only available to users with no integration or with Metrc V2
  const usingValidIntegration = !!UseMetrcV2 || (!UseMetrc && !UseBioTrack);
  const { perms } = useRecoilValue(userPermissionsAtom);
  const bomPermissions = useMemo(
    () => ({
      canViewBoms: !!perms[Permissions.ViewBillOfMaterial] && usingValidIntegration,
      canEditBoms: !!perms[Permissions.EditBillOfMaterial] && usingValidIntegration,
      canViewAssemblies: !!perms[Permissions.ViewAssembly] && usingValidIntegration,
      canEditAssemblies: !!perms[Permissions.EditAssembly] && usingValidIntegration,
    }),
    [perms, usingValidIntegration]
  );
  return bomPermissions;
}
