import { useEffect, useRef, useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { FilterOperator } from 'src/app/queries/types/gql-filtering-and-pagination';
import { settingsAtom } from 'src/app/state/settings';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';

import type { GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid-pro';
import type { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import type { ServerPaginatedTables } from 'src/app/constants/table-names-paginated';
import type {
  Direction,
  FilterDefinition,
  QueryProps,
  SortItem,
} from 'src/app/queries/types/gql-filtering-and-pagination';

/**
 * Base filter model definition, extended to allow for interfacing with URL params.
 */
export type TableFilterDefinition<T> = FilterDefinition<T> & { filterType?: string; urlParam?: string };

export type UseServerTableControlsProps<TFilter, TSort> = {
  /**
   * Define which table fields are filterable, by which operator, and how those filters are keyed in the URL.
   */
  filterDefinitions: TableFilterDefinition<TFilter>[];
  /**
   * Define a default sort used by the api if the user hasn't added one manually via the table, or saved
   * one in settings. This value will be transformed and passed to the datagrid in its respective shape.
   */
  initialSort: SortItem<TSort>[];
  tableName: ServerPaginatedTables;
};

/**
 * Props returned by the hook which are intended to be spread onto the DataGrid to keep its state in sync with the URL/API data flow.
 */
export type TableProps = {
  filterMode: 'server';
  initialState: GridInitialStatePro;
  keepNonExistentRowsSelected: true;
  name: string;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
  page: number;
  paginationMode: 'server';
  rowsLoadingMode: 'server';
  sortingMode: 'server';
  usingServerTableControls: true;
};

export type UseServerTableControlsReturn<TFilter, TSort> = {
  /**
   *
   * the key should always be one of the url params defined in the component's filter definitions
   */
  getFilterValue: (key: string) => (number | string)[] | boolean | number | string;
  queryProps: QueryProps<TFilter, TSort>;
  /**
   *
   * the key should always be one of the url params defined in the component's filter definitions
   */
  setFilterValue: (
    key: string,
    value: (number | string)[] | boolean | number | string | undefined,
    searchParams?: URLSearchParams
  ) => void;
  tableProps: TableProps;
};

export function useServerTableControls<TFilter, TSort>({
  initialSort,
  filterDefinitions,
  tableName,
}: UseServerTableControlsProps<TFilter, TSort>): UseServerTableControlsReturn<TFilter, TSort> {
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useRecoilValue(userDispensariesAtom);
  const {
    tableSettings,
    selectedLocation: { LocId },
  } = user;

  const userSettings = useRecoilValue(settingsAtom);
  const { RowsPerTable } = userSettings;
  const rowsPerTable: number = typeof RowsPerTable === 'string' ? parseInt(RowsPerTable, 10) : RowsPerTable;
  const sortFields = searchParams.get('sortFields');
  const sortDirections = searchParams.get('sortDirections');
  const pageSize = Number(searchParams.get('pageSize') || rowsPerTable);
  const pageInUrl = Number(searchParams.get('page') || '1');
  const currentTable = tableSettings.find((_table) => _table.table === tableName);
  const savedSort = currentTable?.layout?.filter((_column) => !!_column.sort.sort);
  // reference to the previous location id, used to reset the table's search params when the user changes locations
  const prevLocId = useRef(LocId);

  // https://github.com/remix-run/react-router/issues/9991
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSetSearchParams = useCallback(setSearchParams, []);

  // when the user changes the location, reset the table's search params
  useEffect(() => {
    if (prevLocId.current !== LocId) {
      const newSearchParams = new URLSearchParams();
      if (searchParams.has('search')) {
        newSearchParams.set('search', searchParams.get('search') ?? '');
      }
      memoizedSetSearchParams(newSearchParams);
    }
    prevLocId.current = LocId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LocId, memoizedSetSearchParams]);

  // maintains the tables search params in session storage for when the user navigates away from the page and back
  useEffect(() => {
    const currentSearchParams = searchParams.toString();
    const sessionStorageString = currentSearchParams ? `?${currentSearchParams}` : '';
    sessionStorage.setItem(tableName, sessionStorageString);
  }, [searchParams, tableName]);

  // we need to build the sort passed to the api, which can have three defined sources
  // precedence: url > saved table settings > initial sort from dev
  let computedSort: SortItem<TSort>[] = [...initialSort];
  if (sortFields) {
    // Because tables can be sorted based on multiple columns at once, we use commas in the url to separate them.
    // this block of code taks those values from the url and builds an array typed for the server.
    const fieldsArr = sortFields.split(',');
    const directionsArr = sortDirections?.split(',') ?? [];
    const newCurrentSort: SortItem<TSort>[] = [];
    for (let i = 0; i < fieldsArr.length; i += 1) {
      newCurrentSort.push({ key: fieldsArr[i] as keyof TSort, direction: directionsArr[i] as Direction });
    }
    computedSort = newCurrentSort;
  } else if (savedSort?.length) {
    computedSort = savedSort.map((_column) => ({
      key: _column.sort.field as keyof TSort,
      direction: _column.sort.sort as Direction,
    }));
  }

  // from that sort passed to the api, build the equivalent for the table
  const computedSortModel: GridSortItem[] = computedSort.map((_sort) => ({
    field: String(_sort.key),
    sort: (_sort.direction ? _sort.direction.toLowerCase() : 'asc') as GridSortDirection,
  }));

  /**
   *
   * @param key - this should always be one of the url params defined in the component's filter definitions
   * @param value
   */
  function setFilterValue(key: string, value: string, searchParamsArg?: URLSearchParams) {
    // TODO-REFACTOR: type `key` based on passed-in urlParams from model
    const paramsToUse = searchParamsArg ?? searchParams;
    if (value === '' || value === undefined || value === null || (Array.isArray(value) && !value.length)) {
      paramsToUse.delete(String(key));
    } else {
      paramsToUse.set(String(key), value);
    }
    setSearchParams(paramsToUse, { replace: true });
  }

  /**
   * Because we're using the URL as our state source of truth, all values will be written/read as strings.
   * This function converts data back to its correct type (as defined by `initialValue`) when the value is read from the URL.
   * `filterType` is an optional parameter that can be passed in to override the type of the value being read;
   * this is useful for arrays that contain numbers.
   */
  function getValueWithCorrectType<T>(stringVal: string, val: T, filterType = '') {
    const valueType = typeof val;
    if (filterType === 'boolean' || valueType === 'boolean') {
      return stringVal === 'true';
    }
    if (filterType === 'number' || valueType === 'number') {
      return Number(stringVal);
    }
    return stringVal;
  }

  function getValueBasedOnOperator({ urlParam, operator, initialValue, filterType }: TableFilterDefinition<TFilter>) {
    if (operator === FilterOperator.IN) {
      const vals = searchParams.get(String(urlParam))?.split(',') ?? [];
      if (vals.length) {
        return vals
          .map((val) => getValueWithCorrectType(val, initialValue[0], filterType))
          .filter((val) => val !== '' && val !== null && val !== 0);
      }
      if (Array.isArray(initialValue) && initialValue.length) {
        return initialValue.map((n) => n).filter((val: number | string) => val !== '' && val !== null && val !== 0);
      }
      return initialValue;
    }
    return getValueWithCorrectType(
      searchParams.get(String(urlParam)) ?? String(initialValue),
      initialValue,
      filterType
    );
  }

  // map through passed in filters and inject values from the url
  const filterModel = filterDefinitions.map(
    ({
      urlParam,
      fields,
      operator,
      logicalOperator,
      initialValue,
      customValueToApi,
      field,
      filterType,
      oneToManyFields,
    }) => ({
      customValueToApi,
      urlParam,
      fields,
      field,
      operator,
      oneToManyFields,
      logicalOperator,
      value: getValueBasedOnOperator({ urlParam, fields, operator, logicalOperator, initialValue, filterType }),
    })
  );

  /**
   *
   * @param key - this should always be one of the url params defined in the component's filter definitions
   */
  function getFilterValue(key: string) {
    // TODO-REFACTOR: derive `key` type from keys on filterModel
    const filterModelItem = filterModel.find((filterDef) => filterDef.urlParam === key);

    if (filterModelItem?.operator === FilterOperator.IN) {
      return (filterModelItem?.value as (number | string)[])?.filter((val) => val !== '' && val !== 0) ?? [];
    }

    return filterModelItem?.value ?? '';
  }

  // Although this function and `setFilterValue` are similar, they are used by two different scenarios
  // and their type differences are important
  function setUrlParam(key: 'page' | 'pageSize' | 'sortDirections' | 'sortFields', value: string) {
    if (value === '') {
      searchParams.delete(String(key));
    } else {
      searchParams.set(String(key), value);
    }
    setSearchParams(searchParams);
  }

  /**
   * Used internally by DataGrid to write sort params to the URL when column headers are clicked.
   */
  function onSortModelChange(_sortModel: GridSortModel) {
    const newSortFields = _sortModel.map((_sort) => _sort.field).join(',');
    const newSortDirections = _sortModel.map((_sort) => _sort.sort).join(',');

    setUrlParam('sortFields', newSortFields);
    setUrlParam('sortDirections', newSortDirections);
  }

  return {
    getFilterValue,
    setFilterValue,
    queryProps: {
      page: pageInUrl - 1, // this number is passed to the server
      pageSize,
      filterModel,
      sort: computedSort,
      tableName,
    },
    tableProps: {
      initialState: {
        sorting: {
          sortModel: computedSortModel,
        },
      },
      onPageChange: (_page) => setUrlParam('page', String(_page + 1)),
      onPageSizeChange: (_pageSize) => setUrlParam('pageSize', String(_pageSize)),
      onSortModelChange,
      paginationMode: 'server',
      rowsLoadingMode: 'server',
      sortingMode: 'server',
      filterMode: 'server',
      page: pageInUrl - 1, // this number is passed to the table
      keepNonExistentRowsSelected: true,
      name: tableName,
      usingServerTableControls: true,
    },
  };
}
