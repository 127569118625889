import { useEffect, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import type { URLSearchParamsInit } from 'react-router-dom';

/**
 * Retrieves the active tab index based on the URL search parameters and a given set of tabs.
 * @param {URLSearchParams} searchParams - The URL search parameters.
 * @param {Tabs} tabs - An array of string used as the `queryParams`.
 * @returns {number} The index of the active tab. If the tab is not found, returns 0.
 */
export const getActiveTab = (searchParams: URLSearchParams, tabs: string[]) => {
  const tabQueryParam = searchParams.get('tab');
  const tabIndex = tabs.findIndex((tab) => tab === tabQueryParam);
  return tabIndex !== -1 ? tabIndex : 0;
};

/**
 * Hook to manage tab selection and URL query parameters for tabs.
 * @param {Tabs} tabs - An array of string used as the `queryParams`.
 * @param {number} [defaultIndex=0] - The default index to use if no tab is selected in the URL. Defaults to 0.
 * @returns {object} An object containing the following properties:
 * - `activeTab`: The index of the currently active tab based on the URL query parameter.
 * - `setActiveTab`: A function to update the active tab and set it in the URL query parameters.
 */
export function useTabsWithQueryParams(tabs: string[], defaultIndex = 0) {
  const defaultTabName = tabs[defaultIndex] ?? '';
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = useMemo(() => getActiveTab(searchParams, tabs), [searchParams, tabs]);

  // If no tab is selected, set the default tab to the defaultIndex
  useEffect(() => {
    if (!searchParams.get('tab')) {
      searchParams.set('tab', defaultTabName);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, defaultTabName]);

  // Event Handlers
  const setActiveTab = (tab: number) => {
    // Don't update the tab if it's invalid
    if (!(tab in tabs)) {
      return;
    }
    const newParams: URLSearchParamsInit = { tab: tabs[tab] };
    setSearchParams(newParams);
  };

  return { activeTab, setActiveTab };
}
