import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { CreateAssembly } from './detail/create';
import { EditAssembly } from './detail/edit';
import { AssembliesList } from './list';

function Assemblies() {
  return (
    <Routes>
      <Route element={<AssembliesList />} path='/' />
      <Route element={<EditAssembly />} path='/:assemblyId' />
      <Route element={<CreateAssembly />} path='/create' />
    </Routes>
  );
}

export default Assemblies;
