import React from 'react';

export function ErrorIcon({ fill = 'var(--color-red-60)' }: { fill?: string }): JSX.Element {
  return (
    <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 3.32249e-06C5.85856 0.033416 3.81611 0.907386 2.31333 2.43334C1.56533 3.18123 0.975142 4.07172 0.577764 5.052C0.180386 6.03227 -0.016087 7.08237 -1.99222e-06 8.14C-0.00088039 9.173 0.202046 10.196 0.597154 11.1505C0.992262 12.1049 1.57179 12.972 2.30254 13.7022C3.03329 14.4323 3.9009 15.0111 4.85569 15.4054C5.81047 15.7997 6.83367 16.0018 7.86666 16H8C10.14 15.9806 12.1848 15.1124 13.685 13.586C15.1851 12.0596 16.0178 10.0001 16 7.86C16.002 6.81541 15.7954 5.78094 15.3923 4.81726C14.9892 3.85357 14.3977 2.98007 13.6526 2.24798C12.9075 1.51589 12.0237 0.939949 11.053 0.553931C10.0823 0.167913 9.04439 -0.0204111 8 3.32249e-06ZM7 11.0267C6.99455 10.8926 7.01648 10.7589 7.06446 10.6336C7.11244 10.5084 7.18546 10.3942 7.27905 10.2981C7.37265 10.202 7.48485 10.126 7.60881 10.0748C7.73277 10.0235 7.86587 9.99808 8 10C8.26258 10.0015 8.51445 10.1042 8.7032 10.2868C8.89196 10.4693 9.00304 10.7176 9.01333 10.98C9.01873 11.112 8.99736 11.2438 8.95054 11.3673C8.90371 11.4909 8.83238 11.6037 8.74083 11.699C8.64929 11.7943 8.53942 11.87 8.41784 11.9218C8.29625 11.9735 8.16546 12.0001 8.03333 12C7.76841 12.0037 7.51243 11.9043 7.31956 11.7226C7.1267 11.5409 7.01209 11.2913 7 11.0267ZM7.33333 8.36V4.36C7.33333 4.18319 7.40357 4.01362 7.52859 3.8886C7.65362 3.76357 7.82319 3.69334 8 3.69334C8.17681 3.69334 8.34638 3.76357 8.4714 3.8886C8.59643 4.01362 8.66667 4.18319 8.66667 4.36V8.36C8.66667 8.53681 8.59643 8.70638 8.4714 8.83141C8.34638 8.95643 8.17681 9.02667 8 9.02667C7.82319 9.02667 7.65362 8.95643 7.52859 8.83141C7.40357 8.70638 7.33333 8.53681 7.33333 8.36Z'
        fill={fill}
      />
    </svg>
  );
}
